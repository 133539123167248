import useAuth from "../../../hooks/useAuth";
import {ErrorMessage, Field, Formik} from 'formik';
import './styles.scss'
import {useEffect, useState} from "react";
import {IPlan} from "../../../utils/interfaces";
import * as Yup from "yup";
import {ApiPagarme, ApiService} from "../../../services/api";
import {ToastError} from "../../../services/toast-service";
import ReactLoading from 'react-loading';
import {ROUTES} from "../../../index";
import {Meses, States, Years} from "../../../utils/states";
import {formatCreditCardExpirationDate, formatCreditCardNumber, formatCVV} from "../../../utils";

const billingDetailsSchema = Yup.object().shape({
    card_number: Yup.string()
        .min(16, "Invalid credit card number format. Use format: xxxx-xxxx-xxxx-xxxx")
        .required("Credit card number is required"),
    cvv: Yup.string()
        .max(5, "CVV must be a 3 or 4-digit number")
        .required("CVV is required"),
    currentPlan: Yup.string().required("Por favor, selecione um plano"),
    monthExpiration: Yup.string().required("Mês de expiração é obrigatorio!")
});


interface IData {
    firstName: string
    lastName: string
    currentPlan: string
    "cvv": string
    "card_number": string
    "yearExpiration": string
    "monthExpiration": string
    "address": string
    "address2": string
    "city": string
    "state": string
    "zip": string
}

export const CreditCard = () => {
    useAuth({})
    const [plans, setPlans] = useState<IPlan[]>([] as IPlan[])
    const [data, setData] = useState({})
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)


    const [currentPlan, setCurrentPlan] = useState('')
    console.log(currentPlan)

    const getPlans = async () => {
        try {
            const result = await ApiService.get(`api/plans`)
            const allPlans = result?.data?.data || []
            if (allPlans && allPlans.length > 0) {
                setPlans(allPlans.reverse())
                console.log(allPlans)
                // setCurrentPlan(allPlans[0].id)
            }
        } catch (e: any) {
            ToastError(e.message)
        }
    }

    useEffect(() => {
        getPlans()
    }, [])

    const sendPaymentToPagarme = async (
        {
            card_number,
            cvv,
            monthExpiration,
            yearExpiration,
            firstName,
            lastName,
            city,
            zip,
            address,
            state,
        }: IData
    ) => {
        if (!currentPlan) {
            return
        }
        setLoading(true)
        try {
            const result = await ApiPagarme.post(`core/v5/tokens?appId=${process.env.REACT_APP_APP_ID_PAGARME}`,
                {
                    "card": {
                        number: card_number.replaceAll("-", "").slice(0, 16),
                        holder_name: `${firstName} ${lastName}`,
                        cvv: cvv,
                        exp_month: parseInt(monthExpiration),
                        exp_year: parseInt(yearExpiration)
                    },
                    "type": "card"
                }
            )
            setData(result.data)
            const subscriptionsResult = await ApiService.post(`api/plans/${currentPlan}/subscriptions`, {
                card_token: result.data.id,
                subscription: {
                    "billing_address": {
                        "address": address,
                        "city": city,
                        "state": state,
                        "zip": zip
                    }
                }
            })
            
            setLoading(false)
            window.location.replace(ROUTES.PROFILE)
        } catch (e: any) {
            let erro: any = errors
            setLoading(false)
            ToastError(e.message)

            if (e?.response?.data?.errors && e?.response?.data?.errors['request.card.exp_month']) {
                erro = [...e?.response?.data?.errors['request.card.exp_month'], ...erro]
            }
            if (e?.response?.data?.errors && e?.response?.data?.errors['request.card.exp_year']) {
                erro = [...e?.response?.data?.errors['request.card.exp_year'], ...erro]
            }
            if (e?.response?.data?.errors && e?.response?.data?.errors['request.card.holder_name']) {
                erro = [...e?.response?.data?.errors['request.card.holder_name'], ...erro]
            }
            if (e?.response?.data?.errors && e?.response?.data?.errors['request.card.card']) {
                erro = [...e?.response?.data?.errors['request.card.card'], ...erro]
            }

            if(!e?.response) {
                erro = [e.message, ...erro]
            }

            setErrors(erro)
            ToastError(e.message)
        }
    }


    function formatZipCode(zipCode: string) {
        const zipCodePattern = /^(\d{5})(\d{3})$/;
        return zipCode?.replace(zipCodePattern, "$1-$2")?.slice(0, 9);
    }


    const initialValues = {
        firstName: "",
        lastName: "",
        cvv: "",
        card_number: "",
        yearExpiration: "",
        monthExpiration: "",
        address: "",
        address2: "",
        currentPlan: "",
        city: "",
        state: "",
        zip: "",
    };

    return <div className="section section-padding credit-card">
        <div className="container">
            {errors.map((error, index) => <div className="checkout-info" key={index}>
                <p className="info-header error">
                    <i className="fa fa-exclamation-circle"/>
                    <strong>Error:</strong> {error}
                </p>
            </div>)}
            <div className="checkout-info">
                <Formik
                    initialValues={initialValues}
                    validationSchema={billingDetailsSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        setTimeout(async () => {
                            await sendPaymentToPagarme(values)
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          setFieldValue,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="row my-4">
                                <div className="col-lg-7 my-4">
                                    <div className="checkout-form">
                                        <div className="checkout-title">
                                            <h4 className="title">Detalhes de Pagamento</h4>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-6">

                                                <div className="form-group">
                                                    <Field type="text" name="firstName" placeholder="Nome *"
                                                           disabled={loading}
                                                           className='form-control mb-3 custom-input'/>
                                                    <ErrorMessage name="firstName" component="div" className="error"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <Field className='form-control mb-3 custom-input' type="text"
                                                           name="lastName" placeholder="Sobrenome *"
                                                           disabled={loading}/>
                                                    <ErrorMessage name="lastName" component="div" className="error"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <Field className='form-control mb-3 custom-input' type="text"
                                                           placeholder="Número de Cartão de Credito"
                                                           value={formatCreditCardNumber(values.card_number)}
                                                           name='card_number'
                                                           onChange={(e: any) => {
                                                               const text = e.target.value?.slice(0, 19)
                                                               setFieldValue('card_number', formatCreditCardNumber(text))
                                                               handleChange(e)
                                                           }}
                                                           disabled={loading}
                                                    />
                                                    <ErrorMessage name="card_number" component="div" className="error"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">

                                                    <Field className='form-control mb-3 custom-input' type="text"
                                                           name="cvv"
                                                           value={formatCVV(values.cvv)}
                                                           onChange={(e: any) => {
                                                               setFieldValue('cvv', formatCVV(e.target.value?.slice(0, 4)))
                                                               handleChange(e)
                                                           }}
                                                           placeholder="Codigo de Segurança (CVV)"
                                                           disabled={loading}
                                                    />
                                                    <ErrorMessage name="cvv" component="div" className="error"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <Field as="select" name="monthExpiration"
                                                           className="form-select mb-3"
                                                           disabled={loading}>
                                                        <option value="" disabled>
                                                            Mês *
                                                        </option>
                                                        {
                                                            Meses.map(month => {
                                                                return <option
                                                                    value={month.value}>{month.month}</option>
                                                            })
                                                        }
                                                    </Field>
                                                    <ErrorMessage name="monthExpiration" component="div"
                                                                  className="error"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="single-select2">
                                                    <div className="form-group">
                                                        <Field as="select" name="yearExpiration"
                                                               className="form-select mb-3"
                                                               disabled={loading}>
                                                            <option value="" disabled>
                                                                Ano *
                                                            </option>
                                                            {
                                                                Years.map(year => {
                                                                    return <option
                                                                        value={year.value}>{year.year}</option>
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="yearExpiration" component="div"
                                                                  className="error"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <Field className='form-control mb-3 custom-input' type="text"
                                                           name="address" placeholder="Endereço completo"
                                                           disabled={loading}/>
                                                    <Field className='form-control mb-3 custom-input' type="text"
                                                           name="address2" placeholder="Complemento"
                                                           disabled={loading}/>
                                                    <ErrorMessage name="address" component="div" className="error"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <Field className='form-control mb-3 custom-input' type="text"
                                                           name="city" placeholder="Cidade"
                                                           disabled={loading}/>
                                                    <ErrorMessage name="city" component="div" className="error"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="single-select2">
                                                    <div className="form-group">
                                                        <Field as="select" name="state" className="form-select mb-3"
                                                               disabled={loading}>
                                                            <option value="" disabled>
                                                                Selecione um estado *
                                                            </option>
                                                            {
                                                                States.map(state => {
                                                                    return <option
                                                                        value={state.sigla}>{state.nome}</option>
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="state" component="div" className="error"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <Field className='form-control mb-3 custom-input' type="text"
                                                           disabled={loading}
                                                           onChange={(e: any) => {
                                                               setFieldValue('zip', formatZipCode(e.target.value))
                                                               handleChange(e)
                                                           }}
                                                           value={formatZipCode(values.zip)}
                                                           name="zip" placeholder="Cep"/>
                                                    <ErrorMessage name="zip" component="div" className="error"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                {errors.currentPlan && currentPlan === '' ?
                                                    <div className='field-error pb-3'>Por favor, selecione um
                                                        plano!</div> : null}
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <button type='submit'
                                                            className='btn btn-primary btn-hover-dark d-block w-100'
                                                            disabled={loading || !currentPlan}>
                                                        {loading ? <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <div style={{marginLeft: 10}}>Enviar</div>
                                                            <ReactLoading type={'balls'} color={'#ffffff'}
                                                                          height={'10px'} width={'50px'}/>
                                                        </div> : <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <div style={{marginLeft: 10}}>Enviar</div>
                                                        </div>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 my-4">
                                    <div className="checkout-order">
                                        {
                                            plans?.map((plan, index) => {
                                                return <div id={plan.id} className="plans" key={index * 10}>
                                                    <div className="checkout-order-table table-responsive mb-2">
                                                        <section className="table">
                                                            <div>
                                                                <p className="Product-name plans__id">
                                                                    Plano {plan.id}
                                                                </p>
                                                            </div>
                                                            
                                                            <section className="plans__row">
                                                                <div className="Product-name">
                                                                    <h3 className="plans__name">{plan.attributes.name}</h3>
                                                                </div>
                                                                <div className="Product-price">
                                                                    <h3 className="plans__name">R$ {parseInt(plan.attributes.price)}
                                                                        <span className="plans__p">por mês</span></h3>
                                                                </div>
                                                                <div>
                                                                    {plan.id === "1" ?
                                                                        <p>No plano ouro você terá acesso a plataforma e
                                                                            todas as ofertas de todos os fornecedores,
                                                                            como benefício extra, poderá comprar
                                                                            produtos da linha Welt com valores de
                                                                            importação, esse pedido terá limite de R$
                                                                            7.000,00 e poderá ser feito em meses
                                                                            intercalados, além disso, o valor que
                                                                            exceder os R$ 7.000,00 terá um desconto
                                                                            exclusivo, no mês em que o plano não
                                                                            permitir a compra de R$ 7.000,00 a valor de
                                                                            importação, todo pedido terá um desconto
                                                                            exclusivo.</p> :
                                                                        <p>No plano diamante, você terá acesso a
                                                                            plataforma e todas as ofertas de todos os
                                                                            fornecedores, como benefício extra, poderá
                                                                            comprar produtos da linha Welt com valores
                                                                            de importação todos os meses, nesse plano o
                                                                            pedido mínimo não poderá ser inferior a R$
                                                                            10.000,00, sendo, R$ 7.000,00 a valor de
                                                                            importação e o restante com descontos
                                                                            exclusivos.</p>}
                                                                </div>
                                                            </section>
                                                        </section>
                                                    </div>
                                                    <div className="checkout-payment">
                                                        <div className="form-group">
                                                            {currentPlan === plan.id ? <a
                                                                className="btn btn-hover-primary btn-hover-dark d-block text-white plans__button plans__button--selected"
                                                                onClick={(e: any) => {
                                                                    if (loading) {
                                                                        return
                                                                    }

                                                                    setFieldValue('currentPlan', plan.id)
                                                                    handleChange(e)
                                                                    setCurrentPlan(plan.id)
                                                                }}
                                                                href="#">Plano Selecionado</a> : <a
                                                                onClick={(e: any) => {
                                                                    if (loading) {
                                                                        return
                                                                    }
                                                                    setFieldValue('currentPlan', plan.id)
                                                                    handleChange(e)
                                                                    setCurrentPlan(plan.id)
                                                                }}
                                                                className="btn btn-primary btn-hover-dark d-block plans__button"
                                                                href="#"
                                                                id={plan.id}
                                                            >Selecionar
                                                                este
                                                                plano</a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    </div>

}