import axios from "axios";
import {TokensEnum} from "../utils";

export const GET_TOKEN = () => {
    if (typeof window == 'undefined') {
        return
    }
    return localStorage?.getItem(TokensEnum.KEY_TOKEN)
}

export const ApiService = axios.create({
    baseURL: process.env.REACT_APP_HOST_API,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `${GET_TOKEN()}`,
    },
})
export const ApiService2 = axios.create({
    baseURL: process.env.REACT_APP_HOST_API_2,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `${GET_TOKEN()}`,
    },
})
export const ApiPagarme = axios.create({
    baseURL: process.env.REACT_APP_HOST_PAGARME,
})
