import { HashLink } from "react-router-hash-link"

const Countdown = () => {
  return <section id='countdown' className="learn-section">
      <div className="container">
          <div className="row">
              <div className="col-md-6">
                  <div className="learn-text"><h2 className="learn-title">Conheça melhor a <br/><span
                      className="learn-title-alt">XEQUE-MATE</span></h2>
                      <p className="learn-p">Bem-vindo ao mundo exclusivo do Clube VIP de Compras Xeque Mate! Como
                          membro,
                          você terá acesso privilegiado a uma ampla variedade de benefícios especiais. Contamos com
                          diversos
                          fornecedores do segmento moveleiro que oferecem uma seleção especial de produtos de alta
                          qualidade e
                          preços competitivos. Além disso, oferecemos descontos exclusivos em nossa linha de produtos,
                          permitindo que você economize dinheiro em suas compras, nossa equipe dedicada estará pronta
                          para
                          auxiliá-lo na seleção dos itens ideais para atender às suas demandas. Não perca a chance de
                          fazer
                          parte de nosso Clube VIP de Compras Xeque Mate. Junte-se a nós hoje mesmo e experimente um
                          novo
                          modelo inovador de excelência em negociações. Xeque Mate, aqui o seu negócio sempre será o
                          vencedor.</p>
                      {/* <a className="btn btn-secondary-main compre-btn" href="javascript:void(0)">compre agora</a> */}
                      <HashLink className="btn btn-secondary-main compre-btn" smooth to="#prices">CADASTRE-SE AGORA</HashLink>
                  </div>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                  <div className="embed-responsive embed-responsive-21by9 justify-content-center">
                      <iframe width="100%" height="250" src="https://www.youtube.com/embed/tbKValWxfS8"
                              className="rounded mt-2 mb-2" title="YouTube video player" frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              style={{
                                  aspectRatio: '16 /9',
                                  width: '100%'
                              }} allowFullScreen></iframe>
                  </div>
              </div>
          </div>
      </div>
  </section>
}
export {Countdown}