import create from 'zustand'

export const useCart = create<any>((set: any) => ({
  cartItems: [] as any[],
  cart: {},
  orders: [] as any[],
  modalOpen: false,
  modalAccountConfirmation: false,
  setCartItems: (cartItems: any) => {
    set((state: any) => ({ ...state, cartItems }))
  },
  setModalOpen: (modalOpen: boolean) => {
    set((state: any) => ({ ...state, modalOpen }))
  },
  setCart: (cart: any) => {
    set((state: any) => ({ ...state, cart }))
  },
  setModalAccountConfirmation: (modalAccountConfirmation: any) => {
    set((state: any) => ({ ...state, modalAccountConfirmation }))
  },
  setOrdersToModal: (orders: any[]) => {
    set((state: any) => ({ ...state, orders }))
  }
}))
