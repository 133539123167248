import {AxiosResponse, AxiosError} from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import {updateCartItem} from '../../utils/axiosCalls';
import {formatervalue, formatString, getTotalByItens, groupCartData, TokensEnum} from '../../utils';
import deleteIcon from "../../assets/images/icons/delete-icon.svg";
import "./ShoppingCartPage.scss"
import {ApiService2} from "../../services/api";
import {useCart} from "../../hooks/useCart";
import useAuth from "../../hooks/useAuth";
import {ToastError} from "../../services/toast-service";
import {ROUTES} from "../../index";
import { Deserializer } from 'jsonapi-serializer';

import './../ProductsPage/ProductsPage.scss'

const ShoppingCartPage = () => {

    useAuth({})
    const authorization = localStorage.getItem(TokensEnum.KEY_TOKEN)

    const navigate = useNavigate();
    const {setCartItems, cartItems, setOrdersToModal, setModalOpen} = useCart()
    const [cart, setCart]: [cart: any, setCart: Function] = useState()
    const [cartId, setCartId] = useState("")
    const [total, setTotal] = useState(0)

    const getShoppingCartPromisse2 = async () => {
        return await ApiService2.get("carts")
            .then((res: AxiosResponse) => {
                new Deserializer({}).deserialize(res.data, function (err: any, cart: any) {
                    setCart(cart)
                    setCartItems(groupCartData(cart["cart-items"]))
                    setCartId(cart.id)
                })
            })
            .catch((error: AxiosError) => {
                console.log(error.response!)
            })
    }

    useEffect(() => {
        getShoppingCartPromisse2()
    }, [])

    useEffect(() => {
        calculateTotal()
    }, [cartItems])

    useEffect(() => {
        calculateTotal()
    }, [cartItems])

     // console.log(cartItems)
    // console.log(cartItems[0]?.items?.sort((a:any,b:any) => {
    //     return a.id - b.id
    // }))

    // console.log(cartItems[0]?.items?.sort((a:any,b:any) => {
    //     return a.id - b.id
    // }).map((item:any) => {
    //     console.log(item.id)
    // }))


    const clickHandlerDeleteItem = (saleId: number | string, cartId: number | string) => {
        deleteCartItem(saleId, cartId)
    }

    const deleteCartItem = (cartId: number | string, saleId: number | string) => {
        try {
            ApiService2
                .delete(`carts/${cartId}/cart_items/${saleId}`, {
                    headers: {
                        'Authorization': authorization
                    }
                })
                .then((res: AxiosResponse) => {
                    getShoppingCartPromisse2()
                })
                .catch((error: any) => {
                    ToastError(error?.response?.data?.message || error.message)
                })
        } catch (e: any) {
            ToastError(e?.response?.data?.message || e.message)
        }
    }

    const calculateTotal = () => {
        const result = cartItems.reduce((accumulator: any, current: any) => {
            return (current.sale?.product?.price * current.quantity) + accumulator
        }, 0)
        setTotal(result)
    }

    const clickHandler: Function = async (saleId: number | string, productId: any, quantity: number) => {
        try {
            await updateCartItem(cartId, productId, saleId, quantity)
            await getShoppingCartPromisse2()
        } catch (e: any) {
            ToastError(e?.response?.data?.message || e.message)
        }
    }

    const sendOrder = async () => {
        try {
            const result = await ApiService2.post(`customers/orders`)
            setOrdersToModal(result?.data?.data)
            setModalOpen(true)
            getShoppingCartPromisse2()
        } catch (e: any) {
            console.log(e?.response?.data?.message || e.message)
            ToastError(e?.response?.data?.message || e.message)
        }
    }

    return (
        <div className='MyCart'>
            <div className="product-page-wrapper">

                <div className="breadcrumb-main-area">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="breadcrumb-left">
                                        <nav>
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Meu Pedido
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="cart-wrapper-main">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cart-main-area">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12">
                                                <div className="cart-item-area">
                                                    <h2>
                                                        Meu Pedido
                                                    </h2>
                                                </div>
                                                {cartItems?.map((cart: any, key: number) => {
                                                    return <div className="cart-item-area" key={key}>
                                                        <h2 className='mt-4'>
                                                            {cart.supplierName || `Sem nome(${key + 1})`}
                                                        </h2>
                                                        <div className="cart-items-main">
                                                            {cart?.items?.sort((a:any, b:any) => {
                                                                return a.id - b.id
                                                            })
                                                            .map((product: any, key: number) => {
                                                                return <div className="cart-item my-4" key={key}>
                                                                    <div className="cart-image">
                                                                        <img
                                                                            src={product?.sale?.product?.images[0]?.image || product.imageUrlBack}
                                                                            alt="Product"/>
                                                                    </div>
                                                                    <div className="cart-title mx-4">
                                                                        <span>{product?.sale?.product?.name}</span>
                                                                        <p className='cart__p'><small>Tipo de unidade: {product?.sale?.product.unit}</small></p>
                                                                        <p className='cart__p'><small>Estoque: {product?.sale?.quantity}</small></p>
                                                                    </div>
                                                                    <div className="quantity-box">
                                                                        <input type="number" className="quantity"
                                                                               onChange={(event) => {
                                                                                   let num = parseInt(event.target.value)
                                                                                   if (num && num > 0) {
                                                                                       if (num > product.sale.quantity) {
                                                                                           num = product.sale.quantity
                                                                                       }
                                                                                       product.quantity = num
                                                                                       clickHandler(product?.sale?.id, product.id, product.quantity)
                                                                                   }

                                                                               }} defaultValue={product.quantity}/>
                                                                    </div>
                                                                    <div className="cart-price">
                                                                        R${(formatervalue(((product?.sale?.price || product.salePrice) * product?.quantity)))}
                                                                    </div>
                                                                    <div className="cart-delete-btn">
                                                                        <button type="button"
                                                                                onClick={() => clickHandlerDeleteItem(product["cart-id"], product.id)}>
                                                                            <i className="fa-regular fa-trash-can"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                })
                                                }
                                            </div>
                                            <div className="col-lg-4 col-md-12">
                                                <div className="cart-summary-area">
                                                    <h2>Resumo</h2>
                                                    <div className="cart-summary-main">

                                                        {cartItems?.map((cart: any, key: number) => {
                                                            return <div className="cart-summary-item">
                                                                <div className='mt-4 text-dark'>
                                                                    {cart.supplierName || `Sem nome(${key + 1})`}
                                                                </div>
                                                                <p>R$ {formatervalue(getTotalByItens(cart?.items) || 0)}</p>
                                                            </div>
                                                        })
                                                        }
                                                        <div className="total-amount">
                                                            <h2>Total </h2>
                                                            <h2>
                                                                {formatervalue(cartItems.reduce((previousValue: number, currentValue: any) => {
                                                                    return getTotalByItens(currentValue.items) + previousValue
                                                                }, 0))}
                                                            </h2>
                                                        </div>
                                                        <div className="order-button">
                                                            <button type="button" onClick={sendOrder}
                                                                    className="btn btn-secondary">ENVIAR PEDIDO
                                                            </button>
                                                            <p className='cart__p2'>Obs: A quantidade do pedido poderá ser ajustada pelo fornecedor conforme a quantidade de itens por caixa.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link to={"/products"}><button className="btn btn-secondary">CONTINUAR COMPRANDO</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ShoppingCartPage;
