import "./ChangeCreditCard.scss";
import {Dialog} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {ErrorMessage, Field, Formik} from 'formik';
import * as Yup from "yup";
import {formatCreditCardExpirationDate, formatCreditCardNumber, formatCVV, TokensEnum} from "../../utils";
import {ApiPagarme, ApiService} from "../../services/api";
import {ToastError, ToastMessage} from "../../services/toast-service";

const initialValues = {
    cardName: "",
    cvv: "",
    card_number: "",
    expiration: "",
};


const billingDetailsSchema = Yup.object().shape({
    card_number: Yup.string()
        .min(16, "Invalid credit card number format. Use format: xxxx-xxxx-xxxx-xxxx")
        .required("Credit card number is required"),
    cardName: Yup.string().required('Name is required'),
    cvv: Yup.string().min(3, 'Min size to be 3 or 4 digits')
        .max(5, "CVV must be a 3 or 4-digit number")
        .required("CVV is required"),
    expiration: Yup.string().min(5,
        "Data de validade do cartão inválida. Use o formato 'MM/YYYY'"
    )
});

const ChangeCreditCard = ({openCreditCard, handleCloseCreditCard, onReloadProfile}: {
    openCreditCard: boolean,
    handleCloseCreditCard: any
    onReloadProfile: any
}) => {

    return (
        openCreditCard  ? <>
            <div className="modal show" id="AddCard" aria-labelledby="AddCardLabel" aria-modal="true" role="dialog" style={{display: 'block'}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" onClick={handleCloseCreditCard} className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-0">
                            <h5 className="modal-title pb-4" id="AddCardLabel">Alteração de cartão</h5>
                            <div className="change-password popup-form-main">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={billingDetailsSchema}
                                    onSubmit={(values, {setSubmitting}) => {
                                        setTimeout(async () => {

                                            try {
                                                const id = JSON.parse(localStorage.getItem(TokensEnum.USER) as string).data.id
                                                const result = await ApiPagarme.post(`core/v5/tokens?appId=${process.env.REACT_APP_APP_ID_PAGARME}`,
                                                    {
                                                        "card": {
                                                            number: values.card_number.replaceAll("-", "").slice(0, 16),
                                                            holder_name: values.cardName,
                                                            cvv: values.cvv,
                                                            exp_month: parseInt(values.expiration.slice(0, 2)),
                                                            exp_year: parseInt(values.expiration.slice(3, 7))
                                                        },
                                                        "type": "card"
                                                    }
                                                )
                                                const subscriptionsResult = await ApiService.post(`api/customers/${id}/update_card`, {
                                                    card_token: result.data.id,
                                                    external_id: id
                                                })
                                                ToastMessage('Cartão de crédito alterado com sucesso!')
                                                onReloadProfile()
                                                handleCloseCreditCard()
                                            } catch (e: any) {
                                                ToastError(e.message)
                                                console.log(e.mesasge)
                                            }
                                            // cardName
                                            // card_number
                                            // cvv
                                            // expiration
                                            // await sendPaymentToPagarme(values)
                                            setSubmitting(false);
                                        }, 400);
                                    }}
                                >
                                    {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            setFieldValue,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            /* and other goodies */
                                        }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-8 mb-3 text-start">
                                                    <label htmlFor="cardNumber" className="form-label">Número do cartão</label>
                                                    <Field
                                                        name="card_number"
                                                        value={formatCreditCardNumber(values.card_number)}
                                                        onChange={(e: any) => {
                                                            const text = e.target.value?.slice(0, 19)
                                                            setFieldValue('card_number', formatCreditCardNumber(text))
                                                            handleChange(e)
                                                        }}
                                                        type="tel"
                                                        autoComplete="cc-number"
                                                        maxLength={19}
                                                        placeholder="xxxx xxxx xxxx xxxx"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="card_number" component="div" className="error"/>
                                                </div>
                                                <div className="col-md-4 mb-3 text-start">
                                                    <label htmlFor="CVV" className="form-label">CVV</label>
                                                    <Field value={formatCVV(values.cvv)}
                                                                onChange={(e: any) => {
                                                                    setFieldValue('cvv', formatCVV(e.target.value?.slice(0, 4)))
                                                                    handleChange(e)
                                                                }}
                                                                type="text"
                                                                inputMode="numeric"
                                                                pattern="\d*"
                                                                maxLength={3}
                                                                placeholder="CVV"
                                                                className="form-control"
                                                    />
                                                    <ErrorMessage name="cvv" component="div" className="error"/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-8 mb-3 text-start">
                                                    <label htmlFor="CardholderName" className="form-label">Nome impresso no cartão</label>
                                                    <Field name="cardName"
                                                                type="text"
                                                                value={values.cardName}
                                                                onChange={(e: any) => {
                                                                    const text = e.target.value
                                                                    setFieldValue('cardName', text)
                                                                    handleChange(e)
                                                                }}
                                                                className="form-control"
                                                        />
                                                        <ErrorMessage name="cardName" component="div" className="error"/>
                                                </div>
                                                <div className="col-md-4 mb-3 text-start">
                                                    <label htmlFor="expiryDate" className="form-label">Data de expiração</label>
                                                    <Field name="expiration"
                                                            value={formatCreditCardExpirationDate(values.expiration)}
                                                            onChange={(e: any) => {
                                                                setFieldValue('expiration', formatCreditCardExpirationDate(e.target.value))
                                                                handleChange(e)
                                                            }}
                                                            type="text"
                                                            autoComplete="cc-exp"
                                                            maxLength={5}
                                                            placeholder="MM/YY"
                                                            className="form-control"
                                                    />
                                                    <ErrorMessage name="expiration" component="div" className="error"/>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-secondary mt-2">Alterar cartão</button>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </> : null
    )
}

export default ChangeCreditCard
