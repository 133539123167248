import React, {useEffect, useRef, useState} from 'react';
import {ProductListView, SetProductListView, Supplier} from '../../types/types';
import {calculateExpirationDate, capitalizeEachWord, formatervalue, formatString, parseImage, TokensEnum} from '../../utils';
import {ProductListing} from '../../types/types';
import {getShoppingCart, getCategories, getSuppliers} from '../../utils/axiosCalls';
import { Link, redirect } from 'react-router-dom';
import { getCustomer } from '../../utils/axiosCalls';
import { ICustomer } from '../../utils/interfaces';
import {useNavigate} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';


import "@splidejs/react-splide/css";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import {Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import "./ProductsPage.scss"
import {ROUTES} from "../../index";
import useAuth from "../../hooks/useAuth";
import {useCart} from "../../hooks/useCart";
import {ApiService2} from "../../services/api";
import {Deserializer} from "jsonapi-serializer";
// @ts-ignore

const ProductsPage = () => {

    const loader = async () => {
        const userPlan = await getCustomer(setCustomer)
        if(customer?.["plan-id"] !== undefined && customer?.["plan-id"] !== null) {
            return redirect("/profile/credit-card")
        }
    }

    useAuth({})
    const [querySupplier, setQuerySupplier] = useState('')
    const [filterByCategory, setFilterByCategory] = useState<string | number>('')
    const [filterBySupplier, setFilterBySupplier] = useState<string | number>('')
    const navigate = useNavigate();
    const [totalPage, setTotalPage] = useState([] as any)

    const authorization = localStorage.getItem(TokensEnum.KEY_TOKEN)
    const [salesXeque, setSalesXeque] = useState<ProductListing[]>([] as ProductListing[])

    const getSales = async () => {
        let query = `?items=9&page=${currentPage}`
        if (querySupplier.length > 0 && querySupplier !== '') {
            query += `&q[product_name_or_supplier_name_cont]=${querySupplier}`
        }

        if (filterByCategory && filterByCategory !== '') {
            query += `&q[product_category_id_eq]=${filterByCategory}`
        }

        if (filterBySupplier && filterBySupplier !== '') {
            query += `&q[supplier_id_eq]=${filterBySupplier}`
        }

        const response = await ApiService2.get(`sales${query}`)

        const totalPagesArray = []

        for (let i = 0; i < (response.headers['total-pages'] || 0); i++) {
            totalPagesArray.push(i + 1)
        }
        setTotalPage(totalPagesArray)
        // const data = await response.json();
        new Deserializer({}).deserialize(response.data, function (err: any, salesList: any) {
            setSalesDataArray(salesList || [])
        })
    }


    const [productListView, setProductListView]: [productListView: ProductListView, setProductListView: SetProductListView] = useState(true);
    const [salesDataArray, setSalesDataArray]: [salesDataArray: any, setSalesDataArray: Function] = useState([]  as any[])
    const [cart, setCart] = useState(null)
    const {setCartItems, cartItems} = useCart()
    const [cartId, setCartId] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [value, setValue] = useState("")
    const [suppliers, setSuppliers] = useState([]  as any[])
    const [ customer, setCustomer ] = useState<ICustomer>({} as ICustomer)

    const getSalesXeque = async () => {
        try {
            const response = await ApiService2.get(`sales?q[highlight_true]=1`)
            new Deserializer({}).deserialize(response.data, function (err: any, salesList: any) {
                setSalesXeque(salesList || [])
            })
        } catch (e: any) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        getCustomer(setCustomer)
    }, [])


    const [categoriesArray, setCategoriesArray] = useState([
        {
            "id": 1,
            "name": "Ferragens",
            "description": "Ferragens",
            "created_at": "2023-07-19T00:30:05.933Z"
        },
        {
            "id": 2,
            "name": "Ferramentas",
            "description": "Ferramentas",
            "created_at": "2023-07-19T00:30:05.937Z"
        },
        {
            "id": 3,
            "name": "Madeira",
            "description": "Madeira",
            "created_at": "2023-07-19T00:30:05.940Z"
        }
    ])

    const changeListView = (e: any) => {
        e.preventDefault()
        if (productListView === true) {
            setProductListView(false)
        } else {
            setProductListView(true)
        }
    }

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        setQuerySupplier(e.currentTarget.value)
    }

    const filterSuppliersWithImage = (suppliers: Supplier[]) => {
        return suppliers.filter((supplier) => {
            return supplier.logo !== null && Object.keys(supplier.logo || {}).length > 0
        });
    }

    const doSearch = (event: any) => {
        event.preventDefault()
        getSales()
    }

    const onClickSearch = (searchTerm: string) => {
        console.log(searchTerm)
        getSales()
    }

    const onFilterByCategory = (id: string|number) => {
        setFilterByCategory(id.toString())
        getSales()
    }

    const onFilterBySupplier = (id: string|number) => {
        setFilterBySupplier(id.toString())
        getSales()
    }

    useEffect(() => {
        getSuppliers(setSuppliers, filterSuppliersWithImage)
    }, [])

    useEffect(() => {
        getSales()
    }, [])

    useEffect(() => {
        getSales()
    }, [currentPage, querySupplier, filterByCategory, filterBySupplier])

    useEffect(() => {
        getShoppingCart(authorization, setCart, setCartItems, setCartId)
    }, [])

    useEffect(() => {
        getSalesXeque()
        getShoppingCart(authorization, setCart, setCartItems, setCartId)
    }, [])

    useEffect(() => {
        getCategories(authorization, setCategoriesArray)
    }, [])

    return (
        <div className="ProductsPage">
            {customer?.["plan-id"] !== undefined && customer?.["plan-id"] !== null? (
                <div className="product-page-wrapper">
                <div className="breadcrumb-main-area">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="breadcrumb-left">
                                        <nav>
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item" ><a href="javascript:void(0)" onClick={() => navigate(ROUTES.PRODUCTS)}>Home</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Produtos</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="filters-right">
                                        <div className="grid-button">
                                            <div className="list-view">
                                                <img src="/assets/images/list-view-icon.svg" alt="Icon"/>
                                            </div>
                                            <div className="grid-view d-none">
                                                <img src="/assets/images/grid-view-icon.svg" alt="Icon"/>
                                            </div>
                                        </div>
                                        <div className="filter-main">
                                            <i className="fa-solid fa-filter"></i>
                                            Filter
                                        </div>
                                        <div className="search-main">
                                            <input className="form-control" type="text" placeholder=""
                                                   value={querySupplier} onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="promo-bannar-area">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="promo-banner-main">
                                        <div className="promo-banner-image">
                                            <img src="/assets/images/promo-banner.png" alt="Promo Banner"/>
                                        </div>
                                        <div className="promo-products-main">
                                            <Swiper className='swiper'
                                                //@ts-ignore
                                                modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                                                navigation
                                                scrollbar={{ draggable: true }}
                                                onSwiper={(swiper) => console.log(swiper)}
                                                onSlideChange={() => console.log('slide change')}
                                                spaceBetween={50}
                                                slidesPerView={3}
                                                autoplay={true}
                                            >
                                                {
                                                    salesXeque.map((sale: any, index) => {
                                                        return <SwiperSlide key={index}>

                                                            <div className="productPromoSlider" style={{
                                                                maxWidth: '190px'}}>
                                                                <div className="item">
                                                                    <div className="product-box promo-product-box">
                                                                        <div className="product-image">
                                                                            <a href="javascript:void(0)" onClick={() => navigate(`${ROUTES.PRODUCTS}/${sale.id}`)}>
                                                                                <img src={sale?.product?.images[0]?.thumb}
                                                                                                              alt="Product Image"/>
                                                                            </a>
                                                                            <div className="product-time-date">
                                                                                {calculateExpirationDate(sale?.["expiration-date"])}
                                                                            </div>
                                                                        </div>
                                                                        <div className="product-content">
                                                                            <div className="product-content-left">
                                                                                <a href="javascript:void(0)">{capitalizeEachWord(sale?.product?.name)}</a>
                                                                                <p>{sale?.product?.unit}</p>
                                                                            </div>
                                                                            <div className="product-content-right price-section">
                                                                                <h3>R$ {formatervalue(parseFloat(sale?.price))}</h3>
                                                                                <button type="button" onClick={() => navigate(`${ROUTES.PRODUCTS}/${sale.id}`)}><i
                                                                                    className="fa-solid fa-circle-plus"></i></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    })
                                                }
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product-main-area">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="category-left-menu">
                                        <ul>
                                            <li className={filterByCategory === '' ? "products__category active" : 'products__category'}>
                                                <a href="javascript:void(0)" onClick={() => onFilterByCategory('')}>
                                                    Todas
                                                    <i
                                                        className="fa-solid fa-chevron-right"></i>
                                                </a>
                                            </li>
                                            {categoriesArray.map((category) => {
                                                return (
                                                    <li className={filterByCategory === category.id ? "products__category active" : 'products__category'}>
                                                        <a href="javascript:void(0)" onClick={() => onFilterByCategory(category.id)}>
                                                            {category.name}
                                                        <i
                                                            className="fa-solid fa-chevron-right"></i>
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="category-slider-main partners-slider-main">
                                        <h3>Nossos parceiros fornecedores</h3>
                                        <OwlCarousel className='partnersSlider owl-theme'
                                            loop
                                            margin={15}
                                            nav
                                            mouseDrag
                                            navText={["<div class='left-nav'><i class='fa-solid fa-angle-left'></i></div>","<div class='right-nav'><i class='fa-solid fa-angle-right'></i></div>"]}
                                            items={3}
                                        >

                                            {
                                                suppliers.map((supplier) => (
                                                    <div className="item" key={supplier.id}>
                                                        <div className="category-box">
                                                            <a href="javascript:void(0)" onClick={() => onFilterBySupplier(supplier.id)}>
                                                                <div className="category-icon">
                                                                    <img src={supplier.logo.thumb} alt="Icon"/>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </OwlCarousel>
                                    </div>

                                    <div className="product-grid-main">
                                        <div className="row">
                                        {
                                            salesDataArray.map((saleXeque: any, key: number) => {
                                                return <div className="col-lg-4 col-md-6 col-sm-6" key={key}>
                                                    <div className="product-box">
                                                        <div className="product-image">
                                                            <a href="javascript:void(0)" onClick={() => navigate(`${ROUTES.PRODUCTS}/${saleXeque.id}`)}>
                                                                <img src={parseImage(saleXeque?.product?.images[0]?.thumb)}
                                                                     alt="Product Image"/>
                                                            </a>
                                                            <div className="product-time-date">
                                                                <h3>{calculateExpirationDate(saleXeque?.["expiration-date"])}</h3>
                                                            </div>
                                                        </div>
                                                        <div className="product-content d-flex flex-wrap align-items-stretch">
                                                            <div className="product-content-left">
                                                                <a href="javascript:void(0)">{capitalizeEachWord(saleXeque?.product?.name)}</a>
                                                                <p><small>Tipo de Unidade: {saleXeque?.product?.unit}</small></p>
                                                                <p><small>Lote disponível: {saleXeque?.quantity}</small></p>
                                                            </div>
                                                            <div className='d-flex flex-wrap justify-content-between align-items-center product-content-sale'>
                                                                <div className="text-dark price-section">
                                                                    <h3>R$ {formatervalue(parseFloat(saleXeque?.price))}</h3>
                                                                </div>
                                                                <div className="product-content-right">
                                                                    <button type="button" onClick={() => navigate(`${ROUTES.PRODUCTS}/${saleXeque.id}`)}>
                                                                        <i className="fa-solid fa-circle-plus"></i>
                                                                        <span>Adicionar ao carrinho</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }

                                            )
                                        }
                                        </div>
                                        <div className="row">
                                            <div className="page-pagination">
                                                <ul className="pagination justify-content-center">
                                                    <li className="page-item">
                                                        <a className="page-link" href="javascript:void(0)" onClick={() => {
                                                            if (currentPage === 1) {
                                                                return
                                                            }
                                                            setCurrentPage(currentPage - 1)
                                                        }}>
                                                            {'<'}
                                                        </a>
                                                    </li>
                                                    {totalPage.map((page: any, key: any) => <li className="page-item" key={key} onClick={() => {
                                                        setCurrentPage(page)
                                                    }}>
                                                        <a className={currentPage === page ? "page-link active" : " page-link"} href="javascript:void(0)">{page}</a>
                                                    </li>)}

                                                    <li className="page-item" onClick={() => {
                                                        if (totalPage.length === currentPage) {
                                                            return
                                                        }
                                                        setCurrentPage(currentPage + 1)
                                                    }}>
                                                        <a className="page-link" href="javascript:void(0)">
                                                            {`>`}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            ) : 
            <div className='noplan'>
                <h1>Favor inserir os dados de seu cartão de crédito para assinar um de nossos planos para ter acesso a diversas ofertas</h1>
                <Link className="text-decoration-none warning__link" to={'/profile/credit-card'}><button className='noplan__button'>CLIQUE AQUI PARA TENTAR ASSINAR DE NOVO</button></Link>
            </div>
        }
        </div>
    );
};

export default ProductsPage;
