export const States = [
    {"nome": "Acre", "sigla": "AC"},
    {"nome": "Alagoas", "sigla": "AL"},
    {"nome": "Amapá", "sigla": "AP"},
    {"nome": "Amazonas", "sigla": "AM"},
    {"nome": "Bahia", "sigla": "BA"},
    {"nome": "Ceará", "sigla": "CE"},
    {"nome": "Distrito Federal", "sigla": "DF"},
    {"nome": "Espírito Santo", "sigla": "ES"},
    {"nome": "Goiás", "sigla": "GO"},
    {"nome": "Maranhão", "sigla": "MA"},
    {"nome": "Mato Grosso", "sigla": "MT"},
    {"nome": "Mato Grosso do Sul", "sigla": "MS"},
    {"nome": "Minas Gerais", "sigla": "MG"},
    {"nome": "Pará", "sigla": "PA"},
    {"nome": "Paraíba", "sigla": "PB"},
    {"nome": "Paraná", "sigla": "PR"},
    {"nome": "Pernambuco", "sigla": "PE"},
    {"nome": "Piauí", "sigla": "PI"},
    {"nome": "Rio de Janeiro", "sigla": "RJ"},
    {"nome": "Rio Grande do Norte", "sigla": "RN"},
    {"nome": "Rio Grande do Sul", "sigla": "RS"},
    {"nome": "Rondônia", "sigla": "RO"},
    {"nome": "Roraima", "sigla": "RR"},
    {"nome": "Santa Catarina", "sigla": "SC"},
    {"nome": "São Paulo", "sigla": "SP"},
    {"nome": "Sergipe", "sigla": "SE"},
    {"nome": "Tocantins", "sigla": "TO"}

]

export const Meses = [
    {
        "month": "Janeiro",
        "value": 1
    },
    {
        "month": "Fevereiro",
        "value": 2
    },
    {
        "month": "Março",
        "value": 3
    },
    {
        "month": "Abril",
        "value": 4
    },
    {
        "month": "Maio",
        "value": 5
    },
    {
        "month": "Junho",
        "value": 6
    },
    {
        "month": "Julho",
        "value": 7
    },
    {
        "month": "Agosto",
        "value": 8
    },
    {
        "month": "Setembro",
        "value": 9
    },
    {
        "month": "Outubro",
        "value": 10
    },
    {
        "month": "Novembro",
        "value": 11
    },
    {
        "month": "Dezembro",
        "value": 12
    }
]

export const Years = [
    {
        year: 2023,
        value: 23
    },
    {
        year: 2024,
        value: 24
    },
    {
        year: 2025,
        value: 25
    },
    {
        year: 2026,
        value: 26
    },
    {
        year: 2027,
        value: 27
    },
    {
        year: 2028,
        value: 28
    },
    {
        year: 2029,
        value: 29
    },
    {
        year: 2030,
        value: 30
    },
    {
        year: 2031,
        value: 31
    },
    {
        year: 2032,
        value: 32
    },
]