import {Dialog} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import "./AccountConfirmation.scss";
import {useCart} from "../../hooks/useCart";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../index";
import React from "react";

const AccountConfirmation = () => {
    const {setModalAccountConfirmation} = useCart()
    const handleClose = () => {
        navigate(ROUTES.HOME)
        setModalAccountConfirmation(false);
    }

    const navigate = useNavigate();
    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close" onClick={handleClose}></button>
                </div>
                <div className="modal-body pt-0">
                    <h5 className="modal-title pb-4" id="orderConfirmationLabel">Confirmação de criação de conta</h5>
                    <p>
                        Agora sua conta vai passar por uma aprovação técnica<br/>
                        Enviaremos um e-mail com mais informações.</p>
                    {/* <p className="py-3 cursor-pointer"><span>  Clique aqui.</span></p> */}
                  
                  {/* <p className="py-3 cursor-pointer" onClick={() => navigate(ROUTES.HOME)}><span> Login.</span></p> */}
                </div>
            </div>
        </Dialog>
    );
};

export default AccountConfirmation;
