import React, {useEffect, useState} from 'react';
import {showOptionsMenu, TokensEnum} from "../../utils";
import {getShoppingCart} from "../../utils/axiosCalls";
import {useCart} from "../../hooks/useCart";
import {ROUTES} from "../../index";
import { HashLink } from 'react-router-hash-link';
import './Header.scss'

import {useNavigate, Link} from "react-router-dom";

const Header:React.FC = () => {
    const authorization = localStorage.getItem(TokensEnum.KEY_TOKEN)
    const pathname = window.location.pathname
    const isLogged = authorization !== null
    const [ cart, setCart] = useState(null)
    const [cartId, setCartId] = useState(null)
    const navigate = useNavigate()
    const {setCartItems, cartItems} = useCart()

    useEffect(() => {
        getShoppingCart(authorization, setCart, setCartItems, setCartId)
    }, [])

    return <header className='Header'>
        <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img className="img-fluid" src="/assets/images/logo.png" alt="logo"/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="mynavbar">

                    {
                        pathname.toLowerCase().indexOf('login') >= 0 || pathname.toLowerCase().indexOf('register') >= 0 ? null : <>
                            <ul className="navbar-nav flex-fill justify-content-between">
                                {showOptionsMenu() ?isLogged ?  <>
                                        <li className="nav-item"><HashLink className="nav-link" smooth to={ROUTES.MY_REQUEST}>Pedidos</HashLink></li>
                                        <li className="nav-item"><a className="nav-link" href={ROUTES.PRODUCTS}>Ofertas</a></li>
                                        <li className="nav-item"><HashLink className="nav-link" smooth to={ROUTES.PROFILE}>Perfil</HashLink></li>
                                    </> :
                                  <>
                                      <li className="nav-item">
                                          <HashLink className="nav-link" smooth to="#countdown">Quem Somos</HashLink>
                                      </li>
                                      <li className="nav-item">
                                          <HashLink className="nav-link" smooth to="#prices">Planos</HashLink>
                                      </li>
                                      <li className="nav-item">
                                          <HashLink className="nav-link" smooth to="#footer">Contato</HashLink>
                                      </li>
                                      <li className="nav-item">
                                          <HashLink className="nav-link" smooth to="/register?type=fornecedor">Seja um Fornecedor</HashLink>
                                      </li>
                                  </> : null}
                                {
                                    !authorization ? <>  <li className="nav-item">
                                        <HashLink className="nav-link btn btn-primary" smooth to={ROUTES.REGISTER}>Cadastro</HashLink>
                                    </li>
                                    <li className="nav-item" id="login-btn">
                                        <HashLink className="nav-link btn btn-secondary" smooth to={ROUTES.LOGIN}>Login</HashLink>
                                    </li>
                                    </> : <>

                                        <li>
                                            <Link className='header__cart' to={ROUTES.MY_CART}>
                                                <div className='container-shop-icon d-flex'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill='#F26601' height="48" viewBox="0 -960 960 960" width="48"><path d="M286.788-81Q257-81 236-102.212q-21-21.213-21-51Q215-183 236.212-204q21.213-21 51-21Q317-225 338-203.788q21 21.213 21 51Q359-123 337.788-102q-21.213 21-51 21Zm400 0Q657-81 636-102.212q-21-21.213-21-51Q615-183 636.212-204q21.213-21 51-21Q717-225 738-203.788q21 21.213 21 51Q759-123 737.788-102q-21.213 21-51 21ZM235-741l110 228h288l125-228H235Zm-30-60h589.074q22.964 0 34.945 21Q841-759 829-738L694-495q-11 19-28.559 30.5Q647.881-453 627-453H324l-56 104h491v60H277q-42 0-60.5-28t.5-63l64-118-152-322H51v-60h117l37 79Zm140 288h288-288Z"/></svg>
                                                    <div>{cartItems?.length || 0}</div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li className="nav-item" id="login-btn">
                                            <a className="nav-link btn btn-secondary" href="javascript:void(0);" onClick={() => {
                                                localStorage.clear()
                                                window.location.reload()
                                                navigate(ROUTES.LOGIN)
                                            }}>Sair</a>
                                        </li>
                                    </>
                                }

                            </ul>
                        </>
                    }
                </div>
            </div>
        </nav>
    </header>
}

export {Header}