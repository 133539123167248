import axios, {AxiosResponse, AxiosError} from 'axios';
import {ApiService2} from "../services/api";
import { Deserializer } from 'jsonapi-serializer';
import { OrderSummary, Order, OrderItem, Sale, Product, Supplier } from '../types/types';
import { groupPlansById } from '.';
import { ICustomer } from './interfaces';

interface BillingAddress {
    "address": string
    "city": string
    "state": string
    "zip": number
}

//===========GET CALLS==============//

export const getCustomer = (setCustomer: Function) => {
    ApiService2
        .get("current_customer")
        .then((res: AxiosResponse) => {
            new Deserializer({}).deserialize(res.data, function (err: any, customer: any) {
                setCustomer(customer)
            })
        })
        .catch((error: AxiosError) => {
            console.log(error.response!)
        })
}

export const getCustomerPromise = (): Promise<ICustomer|any> => {
    return new Promise((resolve, reject) => {
        ApiService2
            .get("current_customer")
            .then((res: AxiosResponse) => {
                new Deserializer({}).deserialize(res.data, function (err: any, customer: any) {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(customer);
                    }
                })
            })
            .catch((error: AxiosError) => {
                reject(error);
            })
    });
}

export const getShoppingCartPromisse = (authorization: string | null, setCart: Function, setCartItems: Function, setCartId: Function) => {
    ApiService2.get("carts")
    .then((res: AxiosResponse) => {
        new Deserializer({}).deserialize(res.data, function (err: any, cart: any) {
            setCart(cart)
            setCartItems(cart["cart-items"])
            setCartId(cart.id)
        })
    })
    .catch((error: AxiosError) => {
        console.log(error.response!)
    })
}

export const getShoppingCart = (authorization: string | null, setCart: Function, setCartItems: Function, setCartId: Function) => {
    if (!authorization) {
        console.log("Unauthorized to get cart")
        return
    }
        ApiService2
        .get("/carts")
        .then((res: AxiosResponse) => {
            new Deserializer({}).deserialize(res.data, function (err: any, cart: any) {
                setCart(cart)
                setCartItems(cart["cart-items"])
                setCartId(cart.id)
            })
        })
        .catch((error: AxiosError) => {
            console.log(error.response!)
        })
}

export const getCategories = (authorization: string | null, setCategoriesArray: Function) => {
    if (!authorization) {
        console.log("Unauthorized to get categories")
        return
    }
    ApiService2
        .get("/categories")
        .then((res: AxiosResponse) => {
            new Deserializer({}).deserialize(res.data, function (err: any, categories: any) {
                setCategoriesArray(categories || [])
            })
        })
        .catch((error: AxiosError) => {
            console.log(error.response!)
        })
}

export const getCategory = (authorization: string | null, categoryId: number, setCategoriesArray: Function) => {
    if (!authorization) {
        console.log("Unauthorized to get categories")
        return
    }
    ApiService2
        .get(`categories/${categoryId}`)
        .then((res: AxiosResponse) => {
            setCategoriesArray(res.data.data)
            console.log("Get categories was successful")
        })
        .catch((error: AxiosError) => {
            console.log(error.response!)
        })
}

export const getSales = (setSalesDataArray: Function, querySupplier: string) => {
    let query = '?items=10'
    if (querySupplier.length > 0 && querySupplier !== '') {
        query += `&q[product_name_or_supplier_name_cont]=${querySupplier}`
    }
    ApiService2
        .get(`sales${query}`)
        .then((res: AxiosResponse) => {
            // const includedDataArr = res.data.included
            // const productsListings = includedDataArr.filter((listing:any) => listing.type === "product")
            new Deserializer({}).deserialize(res.data, function (err: any, salesList: any) {
                setSalesDataArray(salesList || [])
            })
            console.log("Get list sales was successful")
        })
        .catch((error: AxiosError) => {
            console.log(error.response!)
        })
}


export const getSale = (authorization: string | null, saleId: string, setSale: Function) => {

    if (!authorization) {
        console.log("Unauthorized to get sale")
        return
    }
    ApiService2
        .get(`sales/${saleId}`)
        .then((res: AxiosResponse) => {
            new Deserializer({}).deserialize(res.data, function (err: any, sale: any) {
                setSale(sale)
            })
            console.log("Get sale was successful")
        })
        .catch((error: AxiosError) => {
            console.log(error.response!)
        })
}

export const getPlansMappedById = (setPlans: Function) => {
    ApiService2
        .get("plans")
        .then((res: AxiosResponse) => {
            new Deserializer({}).deserialize(res.data, function (err: any, plans: any) {
                setPlans(groupPlansById(plans))
            })
        })
        .catch((error: AxiosError) => {
            console.log(error.response!)
        })
}

export const getPlan = (planId: number, setPlan: Function) => {
    ApiService2
        .get(`plans/${planId}`)
        .then((res: AxiosResponse) => {
            new Deserializer({}).deserialize(res.data, function (err: any, plan: any) {
                setPlan(plan)
            })

        })
        .catch((error: AxiosError) => {
            console.log(error.response!)
        })
}

export const getPlanPromise = (planId: number): Promise<any> => {
    return new Promise((resolve, reject) => {
      ApiService2
        .get(`plans/${planId}`)
        .then((res: AxiosResponse) => {
          new Deserializer({}).deserialize(res.data, function (err: any, plan: any) {
            if (err) {
              reject(err);
            } else {
              resolve(plan);
            }
          });
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  };

export const getOrders = (setOrders: Function) => {
    ApiService2
        .get('customers/orders')
        .then((res: AxiosResponse) => {
            const groupOrders = (orders:[Order]) => {
                return orders.reduce((obj: {[key: number]: {[key: number]: any}}, order) => {
                    order.orderItems.forEach(orderItem => {
                        (obj[orderItem.sale.product.supplierId][order.id] = obj[orderItem.sale.product.supplierId][order.id] || {})
                        // obj[orderItem.sale.product.supplierId][order.id].supplier = orderItem.sale.product.supplier?.name
                        // (obj[orderItem.sale.product.supplierId][order.id].total = obj[orderItem.sale.product.supplierId][order.id].total || 0)
                        // obj[orderItem.sale.product.supplierId][order.id].total += orderItem.sale.product.supplier?.name
                    });
                    return obj;
                },{})
            }
            let transformDate = (order:any) => {
                // order.created_at = new Date(order.created_at).toLocaleDateString()
                const items = order.order_items.map((order_item:any) => {
                    const supplier: Supplier = {
                        id: order_item.sale.product.supplier.id,
                        name: order_item.sale.product.supplier.name,
                        email: order_item.sale.product.supplier.email,
                        cnpj: order_item.sale.product.supplier.cnpj,
                        address: order_item.sale.product.supplier.address,
                        city: order_item.sale.product.supplier.city,
                        state: order_item.sale.product.supplier.state,
                        zip: order_item.sale.product.supplier.zip,
                        phone: order_item.sale.product.supplier.phone,
                        status: order_item.sale.product.supplier.status,
                        createdAt: new Date(order_item.sale.product.supplier.created_at)
                    }
                    const product: Product = {
                        id: order_item.sale.product.id,
                        price: parseFloat(order_item.sale.product.price),
                        supplier: supplier,
                        images: order_item.sale.product.images,
                        name: order_item.sale.product.name,
                        description: order_item.sale.product.description,
                        sku: order_item.sale.product.sku,
                        unit: order_item.sale.product.unit,
                        supplierId: order_item.sale.product.supplier_id,
                        categoryId: order_item.sale.product.category_id,
                        createdAt: new Date(order_item.sale.product.created_at)
                    };
                    const sale: Sale = {
                        id: order_item.sale.id,
                        product: product,
                        productId: product.id,
                        price: parseFloat(order_item.sale.price),
                        quantity: order_item.sale.quantity,
                        highlight: order_item.sale.highlight,
                        expirationDate: new Date(order_item.sale.created_at),
                        createdAt: new Date(order_item.sale.created_at)
                    }
                    const item: OrderItem = {
                        id: order_item.id,
                        sale_id: order_item.sale_id,
                        sale: sale,
                        quantity: order_item.quantity,
                        status: order_item.status,
                        createdAt: new Date(order_item.created_at)
                    }
                    return item;
                })
                const typedOrder: Order = {
                    id: order.id,
                    createdAt: new Date(order.created_at),
                    orderItems: items
                }
                return typedOrder
            }
            new Deserializer({keyForAttribute: "snake_case", transform: transformDate}).deserialize(
                res.data,
                (err: any, orders: any) => {
                    setOrders(groupOrders(orders))
                }
            )
        })
        .catch((error: AxiosError) => {
            console.log("error while listing orders")
            console.log(error.response!)
        })
}

export const getOrderSummary = (setOrders: Function) => {
    ApiService2
        .get('customers/orders/summary')
        .then((res: AxiosResponse) => {
            let transformDate = (orderSummary:any) => {
                const order: OrderSummary = {
                    id: orderSummary.id,
                    createdAt: new Date(orderSummary.created_at),
                    total: orderSummary.total,
                    supplierName: orderSummary.supplier_name
                    //need order status
                }
                return order
            }
            new Deserializer({keyForAttribute: "snake_case", transform: transformDate}).deserialize(
                res.data,
                (err: any, orders: any) => {
                    setOrders(orders)
                }
            )
        })
        .catch((error: AxiosError) => {
            console.log("error while listing orders")
            console.log(error.response!)
        })
}

export const getSuppliers = (setSuppliers: Function, filterSuppliers?:Function) => {
    ApiService2
        .get("suppliers")
        .then((res: AxiosResponse) => {
            const transformSupplier = (supplier:any): Supplier => {
                return {
                    id: supplier.id,
                    name: supplier.name,
                    email: supplier.email,
                    cnpj: supplier.cnpj,
                    address: supplier.address,
                    city: supplier.city,
                    state: supplier.state,
                    zip: supplier.zip,
                    phone: supplier.phone,
                    status: supplier.status,
                    createdAt: new Date(supplier.created_at),
                    logo: supplier.logo
                };
            }
            new Deserializer({keyForAttribute: "snake_case", transform: transformSupplier}).deserialize(res.data, function (err: any, suppliers: any) {
                let filteredSuppliers = suppliers;
                if (filterSuppliers !== undefined  && filterSuppliers !== null) {
                    filteredSuppliers = filterSuppliers(suppliers)
                }
                setSuppliers(filteredSuppliers)
            })
        })
        .catch((error: AxiosError) => {
            console.log(error.response!)
        })

}

//===========POST CALLS==============//

export const createCartItem = (cartId: number | string, saleId: string | number, quantity: number) => {
    return ApiService2
        .post(`carts/${cartId}/cart_items`, {
            "cart_item": {
                "sale_id": saleId,
                "quantity": quantity
            }
        })
}

export const createSubscription = (authorization: string | null, planId: number, cardToken: number | string, billingAddress: BillingAddress, setPlan: Function) => {
    if (!authorization) {
        console.log("Unauthorized to create subscription")
        return
    }
    ApiService2
        .post(`plans/${planId}/subscriptions`, {
            "card_token": cardToken,
            "subscription": {
                billingAddress
            }
        }, {
            headers: {
                'Authorization': authorization
            }
        })
        .then((res: AxiosResponse) => {
            setPlan(res.data)
            console.log("Create subscription was successful")
        })
        .catch((error: AxiosError) => {
            console.log(error.response!)
        })
}

//NEED CREATEORDER POST CALL
//what to send in data in req?

//===========PUT CALLS==============//

export const updateCartItem = (
    cartId: string | number,
    productId: string | number,
    saleId: number | string,
    quantity: number | string,
) => {
    return ApiService2
        .put(`carts/${cartId}/cart_items/${productId}`, {
            "cart_item": {
                "sale_id": saleId,
                "quantity": quantity
            }
        })
}

//===========DELETE CALLS==============//

//IS THIS CORRECT?
export const deleteCartItem = (authorization: string | null, cartId: number | string, saleId: number | string) => {
    if (!authorization) {
        console.log("Unauthorized to delete item")
        return
    }
    ApiService2
        .delete(`carts/${cartId}/cart_items/${saleId}`)
        .then((res: AxiosResponse) => {
            console.log("Delete cart item was successful")
            window.location.reload();
        })
        .catch((error: AxiosError) => {
            console.log(error.response!)
        })
}
