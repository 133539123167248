import {useEffect, useState} from 'react'
import {ApiService} from "../services/api";
import {TokensEnum} from "../utils";
    import {ROUTES} from "../index";

interface Props {
    redirectUrl?: string
    redirect?: boolean
}

function useUser({redirect = true, redirectUrl = ROUTES.LOGIN}: Props): any {
    const [user, setUser] = useState<
        | {
        token?: string
    }
        | undefined
    >()

    useEffect(() => {
        async function refreshUser() {
            try {
                if (!localStorage.getItem(TokensEnum.KEY_TOKEN)) {
                    setUser(undefined)
                    window.location.replace(redirectUrl)
                } else {
                    const result = await ApiService.get('api/current_customer')
                    localStorage.setItem(TokensEnum.USER, JSON.stringify(result.data))
                    if (!result?.data?.data?.id) {
                        setUser(undefined)
                        localStorage.removeItem(TokensEnum.KEY_TOKEN)
                        localStorage.removeItem(TokensEnum.USER)
                        window.location.reload()
                    } else {
                        setUser({
                            token: localStorage.getItem(TokensEnum.KEY_TOKEN) as string,
                        })
                    }

                }
            } catch (e: any) {
                if (e?.request?.status === 401 || e?.request?.status === 0) {
                    setUser(undefined)
                    localStorage.removeItem(TokensEnum.KEY_TOKEN)
                    localStorage.removeItem(TokensEnum.USER)
                    window.location.reload()
                }
            }
        }

        refreshUser()
    }, [])
    return user
}

export default useUser
