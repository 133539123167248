import {useEffect, useState} from "react";
import {moneyFormat} from "../../utils";
import {getOrderSummary} from "../../utils/axiosCalls";
import {OrderSummary} from "../../types/types";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../index";


const OrderList = () => {
    const [orders, setOrders]: [orders: any, setOrders: Function] = useState()

    const navigate = useNavigate();
    useEffect(() => {
        getOrderSummary(setOrders)
    }, [])

    return (
        <>
            <div className="profile__orders">
                {/* <h3 className="profile__subheader">MEUS PEDIDOS</h3> */}

                {orders ?
                    <table className="table table-striped table-hover table-borderless mt-4">
                        <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Data</th>
                            <th scope="col">Fornecedor(es)</th>
                            {/* <th scope="col">Quantidade</th> */}
                            <th scope="col">Valor Total</th>
                            <th scope="col" className="text-end">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orders.map((order: OrderSummary) => {
                                return (<tr
                                    id={order.id.toString()}
                                    className='cursor-pointer'
                                    onClick={() => navigate(`${ROUTES.MY_REQUEST_DETAILS.replace(':id', '')}${order.id}`)}
                                >
                                    <th scope="row">{order.id}</th>
                                    <td>{order.createdAt.toLocaleDateString()}</td>
                                    <td>{order.supplierName}</td>
                                    <td>{moneyFormat(order.total)}</td>
                                    <td className="text-end"></td>
                                </tr>)
                            }
                        )}
                        </tbody>
                    </table>
                    : ''}
            </div>
        </>
    );
}
export default OrderList;
