import "./ProfilePage.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TokensEnum } from "../../utils";
import { getCustomer, getPlan, getCustomerPromise, getPlanPromise } from "../../utils/axiosCalls";
import ChangePlan from "../../components/ChangePlan/ChangePlan";
import ChangeCreditCard from "../../components/ChangeCreditCard/ChangeCreditCard";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import OrderList from "../../components/OrderList";
import './../ProductsPage/ProductsPage.scss'
import useAuth from "../../hooks/useAuth";
import {ICustomer} from "../../utils/interfaces";

const ProfilePage = () => {
    const [planId, setPlanId] = useState(() => {
        const user = JSON.parse(localStorage.getItem(TokensEnum.USER) || '{}')
        return user?.data?.attributes?.plan_id || 0
    })
    const authorization = localStorage.getItem(TokensEnum.KEY_TOKEN)
    useAuth({})
    const [openPlan, setOpenPlan]: [openPlan: boolean, setOpenPlan: Function] = useState(false);
    const handleOpenPlan = () => setOpenPlan(true);
    const handleClosePlan = () => setOpenPlan(false);

    const handleSelectedPlan = (e: any) => {
        e.preventDefault();
        const selectedPlanArr = document.querySelectorAll(".change-plan__single-plan");
        selectedPlanArr.forEach(plan => {
            plan.classList.remove("selected");
        })
        // setSelectedPlan(e.target.value);
        e.target.closest("button").classList.add("selected");
    }
        //change credit card modal//
    const [openCreditCard, setOpenCreditCard]: [openCreditCard: boolean, setOpenCreditCard: Function] = useState(false);
    const handleOpenCreditCard = () => setOpenCreditCard(true);
    const handleCloseCreditCard = () => setOpenCreditCard(false);

        //change password modal//
    const [openPassword, setOpenPassword]: [openPassword: boolean, setOpenPassword: Function] = useState(false);
    const handleOpenPassword = () => setOpenPassword(true);
    const handleClosePassword = () => setOpenPassword(false);


    const [ customer, setCustomer ] = useState<ICustomer>({} as ICustomer)
    const [ plan, setPlan ]: [plan: any, setPlan: Function] = useState({})

    useEffect(() => {
        async function fetchData() {
            try {
              const c = await getCustomerPromise();
              setCustomer(c as ICustomer);

              if (c?.["plan-id"] &&  c?.["plan-id"]) {
                const p = await getPlanPromise(c?.["plan-id"]);
                setPlan(p);
              }
            } catch (error) {
              console.error("Error fetching customer or plan:", error);
            }
        }
        fetchData();
    }, [])

    //expiry date check for credit card on the file//
    const expiryMonth = 11; //example - needs to be pulled from db
    const expiryYear = 2026; //example - needs to be pulled from db
    // const today = new Date();
    const expiryDate = new Date ();
    expiryDate.setFullYear(expiryYear, expiryMonth, 0);


    return (
        <div className='MyProfile'>
        <div className="product-page-wrapper">
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="profile-card profile-title mb-4">
                                <h1>PERFIL</h1>
                            </div>
                            <div className="profile-card plan-main-area mb-4">
                                <h2>MEU PLANO</h2>
                                <div className="row align-items-center my-5">
                                    <div className="col-md-7">
                                        <p className="mb-3">Plano:</p>
                                        <ChangePlan customer={customer} openPlan={openPlan} handleClosePlan={handleClosePlan} handleSelectedPlan={handleSelectedPlan}/>
                                        <button onClick={handleOpenPlan} type="button" className="btn btn-primary" >Alterar plano
                                        </button>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="d-flex align-items-center">
                                            <img src="/assets/images/queen-icon.svg" alt="Icon"/>
                                            {customer && (<p className="ms-3">{customer?.["plan-id"] !== undefined && customer?.["plan-id"] !== null ? plan?.name?.toUpperCase() : <Link className="text-decoration-none warning__link" to={'/profile/credit-card'}>
                                                ASSINAR UM PLANO
                                            </Link>}
                                            </p>)}
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-md-7">
                                        <p className="mb-3">Cartão de crédito:</p>
                                        <button onClick={handleOpenCreditCard} type="button" className="btn btn-primary">Alterar cartão de crédito
                                        </button>
                                    </div>
                                    <div className="col-md-5">
                                        <p className="">**** **** **** {customer?.last4}</p>
                                        <p>{customer?.["exp-month"]}/{customer?.["exp-year"]}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-card p-4">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <img src="/assets/images/lock-icon.svg" alt="Icon" style={{width: '30px'}}/>
                                            <p className="ms-3">MINHA SENHA</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex justify-content-end">
                                            {/* <ChangePassword openPassword={openPassword} handleClosePassword={handleClosePassword}/> */}
                                            <button onClick={handleOpenPassword} type="button" className="btn btn-primary" data-bs-toggle="modal"
                                                    data-bs-target="#changePassword">Alterar senha
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="profile-card profile-information-main">
                                <h3>MINHAS INFORMAÇÕES</h3>
                                <div className="row my-5">
                                    <div className="col-md-7">
                                        <h4>RAZÃO SOCIAL</h4>
                                        <p>{customer?.name || '-'}</p>
                                    </div>
                                    <div className="col-md-5">
                                        <h4>CNPJ</h4>
                                        <p>{customer?.cnpj}</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>NOME RESPONSÁVEL</h4>
                                        <p>{customer?.name}</p>
                                    </div>
                                </div>

                                <div className="row my-5">
                                    <div className="col-md-7">
                                        <h4>EMAIL</h4>
                                        <p>{customer?.email}</p>
                                    </div>
                                    <div className="col-md-5">
                                        <h4>CELULAR</h4>
                                        <p>{customer?.phone}</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>ENDEREÇO</h4>
                                        <p>{customer?.address}</p>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-7">
                                        <h4>CEP</h4>
                                        <p>{customer?.zip}</p>
                                    </div>
                                    <div className="col-md-5">
                                        <h4>CIDADE</h4>
                                        <p>{customer?.city}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-card mt-5 p-4 order-main-area">
                                <h2>MEUS PEDIDOS</h2>
                                <OrderList />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ChangeCreditCard openCreditCard={openCreditCard} handleCloseCreditCard={handleCloseCreditCard} onReloadProfile={() => {
            getCustomer(setCustomer)
        }}/>



        <div className="modal fade" id="changePassword" data-bs-backdrop="static" data-bs-keyboard="false"
             aria-labelledby="changePasswordLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div className="modal-body pt-0">
                        <h5 className="modal-title pb-4" id="changePasswordLabel">Alteração de senha</h5>
                        <div className="change-password popup-form-main">
                            <form action="#">
                                <div className="mb-3 text-start">
                                    <label htmlFor="newPassword" className="form-label">Nova senha</label>
                                    <input type="password" className="form-control" id="newPassword" placeholder=""
                                           required/>
                                </div>
                                <div className="mb-3 text-start">
                                    <label htmlFor="repeatPassword" className="form-label">Confirmar nova senha</label>
                                    <input type="password" className="form-control" id="repeatPassword" placeholder=""
                                           required/>
                                </div>
                                <button type="submit" className="btn btn-secondary mt-2">Alterar senha</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    )
}

export default ProfilePage;
