export enum TokensEnum {
    KEY_TOKEN = '@XEQUE_MATE_APP',
    USER = '@XEQUE_MATE_APP_USER',
}


export const showOptionsMenu = () => {
    const pathname = window.location.pathname
    return !(pathname.toLowerCase().indexOf('credit-card') >= 0);
}

export function formatString(text: string, maxLength = 16) {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength - 3) + "...";
    }
}
export function capitalizeEachWord(str: string) {
    return str
        .toLowerCase()
        .replace(/(?:^|\s)\w/g, function (match) {
            return match.toUpperCase();
        });
}

export const formatervalue = (numero: number) => {
    return  numero.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

const moneyFormatter = new Intl.NumberFormat(
    'pt-BR',
    { style: 'currency', currency: 'BRL' }
)

export const moneyFormat = (number: number) => {
    return moneyFormatter.format(number)
}
export function formatCreditCardNumber(creditCardNumber: string) {
    const cleanedNumber = creditCardNumber.replace(/\D/g, "");

    const formattedNumber = cleanedNumber.match(/.{1,4}/g)?.join("-") || "";
    return formattedNumber.slice(0, 19);
}

export const formatCVV = (value: any) => {
    const onlyNums = value?.replace(/\D/g, "");

    return onlyNums?.slice(0, 4);
};

// export function formatCreditCardExpirationDate(expirationDate: any) {
//     const month = expirationDate.slice(0, 2);
//     const year = expirationDate.slice(3, 7)
//     if (expirationDate.length < 3) {
//         return month
//     }
//     return `${month}/${year}`;
// }

export const formatCreditCardExpirationDate = (expirationDate: any) => {
    const expdate = expirationDate;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
  };

export function groupCartData(cartItem: any) {
    const groupedData: any = {};

    cartItem.forEach((item: any) => {
        const supplierId = item.sale.product['supplier-id'];
        if (!groupedData[supplierId]) {
            groupedData[supplierId] = {
                supplierId: supplierId,
                supplierName: item.sale.product.supplier.name,
                items: [],
            };
        }
        groupedData[supplierId].items.push(item);
    });

    return  Object.values(groupedData);
}

export const getTotalByItens = (items: any) => {
    return items?.reduce((previousValue: number, currentValue: any) => {
        return currentValue.quantity * parseFloat(currentValue.sale.price) + previousValue
    }, 0)
}

export const parseImage = (image?: string): string => {
    if (image) {
        return image
    }
    return  '/assets/images/empty-cart.svg'
}

export const calculateExpirationDate = (expirationDate: string): string => {
    if (!expirationDate || expirationDate === "") {
        return ""
    }
    const parsedExpirationDate = new Date(expirationDate);
    const now = new Date();

    let diffInMilliseconds = parsedExpirationDate.getTime() - now.getTime();

    if (diffInMilliseconds < 0) {
        diffInMilliseconds = 0;
    }

    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const days = Math.floor(diffInSeconds / 86400);
    const hours = Math.floor((diffInSeconds % 86400) / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    return `${days}D : ${hours}H : ${minutes}min : ${seconds}s`;
}

export const groupPlansById = (plans: []) => {
    const groupedData: any = {};

    plans.forEach((plan:any) => {
        groupedData[plan.id] = plan;
    })

    return groupedData
}
