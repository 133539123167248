export const categories = [
  {
    id: 1,
    title: "Planos",
    questions: [
      {
        id: 1,
        question: "1. Terei acesso a plataforma assinando qualquer um dos planos?",
        answer:
          "Sim, você terá acesso a plataforma assinando qualquer um dos planos, cada plano tem uma característica diferente para transacionar dentro do site.",
      },
      {
        id: 2,
        question: "2. Qual a diferença entre o plano ouro e o plano diamante?",
        answer:
          "No plano ouro você terá acesso a plataforma e todas as ofertas de todos os fornecedores, como benefício extra, poderá comprar produtos da linha Welt com valores de importação, esse pedido terá limite de R$ 7.000,00 e poderá ser feito em meses intercalados, além disso, o valor que exceder os R$ 7.000,00 terá um desconto exclusivo, no mês em que o plano não permitir a compra de R$ 7.000,00 a valor de importação, todo pedido terá um desconto exclusivo.No plano diamante, você terá acesso a plataforma e todas as ofertas de todos os fornecedores, como benefício extra, poderá comprar produtos da linha Welt com valores de importação todos os meses, nesse plano o pedidomínimo não poderá ser inferir a R$ 10.000,00, sendo, R$ 7.000,00 a valor deimportação e o restante com descontos exclusivos.",
      },
      {
        id: 3,
        question:
          "3. Posso comprar a valor de importação produtos Welt todos os meses no plano ouro?",
        answer:
          "Não é possível adquirir produtos Welt a valores de importação todos os meses no plano ouro, somente em meses intercalados, totalizando 6 compras em 12 meses, porém, toda compra em meses que não serão a valores de importação terá descontos exclusivos.",
      },
      {
        id: 4,
        question:
          "4. Assinando o plano ouro já posso comprar a valor de importação?",
        answer:
          "Dependerá se no mês da sua assinatura o “Mês Xeque Mate” estiver em vigor, recomendamos conversar conosco antes para não se sentir prejudicado.",
      },
      {
        id: 5,
        question:
          "5. Assinando o plano diamante, posso comprar todos os meses a valores de importação?",
        answer:
          "Sim, o plano diamante lhe dará direito a comprar todos os meses até R$ 7.000,00 a valores de importação, porém, o pedido mínimo para poder comprar esse valor é de R$ 10.000,00, sendo R$ 7.000,00 a valor de importação e R$ 3.000,00 ou mais com descontos exclusivos.",
      },
    ],
  },
  {
    id: 2,
    title: "Compras",
    questions: [
      {
        id: 1,
        question: "6. O que posso comprar com o plano ouro?",
        answer:
          "Assinando o plano ouro, você poderá negociar todos os itens divulgados pelos fornecedores na plataforma, para o fornecedor Welt, existem regras exclusivas dentro do plano ouro.",
      },
      {
        id: 2,
        question: "7. Posso comprar Welt com o plano ouro?",
        answer:
          "Você poderá comprar todos os produtos Welt divulgados dentro da plataforma, dentro do plano você poderá comprar em meses intercalados a valores de importação direta, sendo o pedido limitado ao valor máximo de R$ 7.000,00, caso queria adquirir mais itens dentro do mês, os valores acima de R$ 7.000,00 terão um desconto exclusivo, porém, não será mais possível dentro do mês comprar a valor de importação.",
      },
      {
        id: 3,
        question: "8. Posso comprar mais de R$ 7.000,00 em produtos Welt sendo do plano ouro?",
        answer:
          "Sim, você poderá comprar o valor de desejar dentro do plano ouro, até R$ 7.000,00 poderá ser a valor de importação (meses intercalados) e o que exceder ao valor de R$ 7.000,00 terá descontos exclusivos.",
      },
      {
        id: 4,
        question: "9. E no mês que não tiver o valor de importação no plano ouro, terei descontos?",
        answer:
          "Sim, nos meses em que o plano ouro não permite a compra a valor de importação, todos os itens Welt estarão com descontos exclusivos.",
      },
      {
        id: 5,
        question: "10. Posso comprar menos que R$ 7.000,00 no plano ouro?",
        answer:
          "Sim, você pode comprar o valor que achar necessário, no plano ouro, qualquer valor abaixo de R$ 7.000,00 será a valor de importação nos meses que o plano permitir essa condição, nos meses em que ele não permitir, terá descontos exclusivos.",
      },
      {
        id: 6,
        question: "11. O representante Welt que hoje me atende, continuará a me atender?",
        answer:
          "Sim, nossos representantes são nossos parceiros e eles receberão uma cópia da sua intenção de compra assim que efetivar a intenção, ele entrará em contato com você para acertar os detalhes do faturamento, forma de pagamento e entrega.",
      },
      {
        id: 7,
        question: "12. Como farei para realizar compra de outros fornecedores que não são Welt?",
        answer:
          "Dentro da plataforma, depois de fazer a assinatura de um dos planos, você acessará a oferta, adicionara ao seu carrinho e emitira um pedido de intenção de compra, essa intenção de compra será enviada para o responsável do fornecedor que entrará em contato com você para acertar os detalhes do faturamento, condição de pagamento, entrega, etc.",
      },
      {
        id: 8,
        question: "13. Posso comprar menos de R$ 10.000,00 no plano diamante?",
        answer:
          "Sim, você poderá comprar qualquer valor que desejar no plano diamante, no entanto, se o valor do seu pedido for inferior ao pedido mínimo de R$ 10.000,00 o pedido não terá valor de importação, apenas o desconto exclusivo de complemento.",
      },
      {
        id: 9,
        question: "14. Posso fazer dois pedidos de R$ 5.000,00 no mês para ter descontos de importação e atingir o valor do pedido mínimo no plano diamante?",
        answer:
          "Você poderá fazer quantos pedido desejar dentro do mês, respeitando o limite de credito cadastrado, no entanto, se optar por fazer um pedido de R$ 5.000,00 o valor de desconto será o desconto exclusivo de complemento, já no segundo pedido, conseguiremos aplicar os valores de importação.",
      },
    ],
  },
  {
    id: 3,
    title: "Acesso",
    questions: [
        {
            id: 1,
            question: "15. Existe fidelidade no plano?",
            answer:
              "Não, a assinatura será cobrada mensalmente, e você pode cancela-la a qualquer momento, a cobrando do mês será realizada normalmente, no próximo mês a cobrança já não existirá novamente.",
          },
          {
            id: 2,
            question: "16. Posso fazer assinatura e cancelar quantas vezes eu quiser?",
            answer:
              "Existe uma regra nos termos que você deverá aceitar que diz que se cancelar duas vezes dentro de 12 meses, seu cadastro ficará inativo por 12 meses, após esse período poderá assinar novamente se assim desejar.",
          },
          {
            id: 3,
            question: "17. Fiquei inativo na plataforma por ter cancelado mais de duas vezes o plano, posso ainda comprar produtos da linha Welt fora da Xeque Mate?",
            answer:
              "Sim, Caso tenha ficado inativo pelo cancelamento da assinatura em mais de duas vezes, os produtos Welt continuarão disponível para venda fora da plataforma.",
          },
          {
            id: 4,
            question: "18. Posso cancelar a qualquer momento?",
            answer:
              "Sim, entendemos que o cancelamento é um direito seu, e poderá fazê-lo a qualquer momento, no entanto, a parcela/assinatura do mês será cobrada normalmente, sendo que as próximas serão canceladas.",
          },
          {
            id: 5,
            question: "19. O cadastro é liberado automaticamente?",
            answer:
              "Existem regras para liberação do cadastro automaticamente, casa não ocorra devido as travas de segurança, nosso pessoal analisará o cadastro, estando dentro das premissas do termo de aceite, seu cadastro será liberado por nosso pessoal.",
          },
    ],
  },
  {
    id: 4,
    title: "Financeiro",
    questions: [
        {
            id: 1,
            question: "20. Qual a validade do plano?",
            answer:
              "A validade da assinatura é para dentro do mês, recomendamos analisar a data da assinatura para não perder as oportunidades do mês.",
          },
          {
            id: 2,
            question: "21. Posso fazer upgrade do plano ouro para o diamante?",
            answer:
              "Sim, a qualquer momento é possível alterar o plano, no entanto, deverá atentar-se para as regras, pois se já fez a compra a valor de importação no fez de alteração, não poderá comprar novamente a valor de importação, somente no próximo mês.",
          },
          {
            id: 3,
            question: "22. Posso fazer downgrade do plano diamante para o ouro?",
            answer:
              "Sim, a qualquer momento é possível alterar o plano, no entanto, deverá atentar-se para as regras, pois se não for o mês em que o valor de importação está ativo para o plano ouro, você poderá se sentir prejudicado.",
          },
          {
            id: 4,
            question: "23. Existe outra forma de assinar os planos?",
            answer:
              "Estamos em constante atualização para melhor lhe atender, no entanto, no momento dispomos apenas de pagamento via cartão de credito para assinatura dos planos, assim que tivermos mais formas de pagamento, vamos atualizar o site.",
          },
          {
            id: 5,
            question: "24. O que devo fazer se trocar de cartão de credito?",
            answer:
              "Caso isso ocorra é necessário que acesso o seu perfil no site e faça a atualização do novo cartão de credito, recomendamos que faça o mais breve possível para não perder o acesso as ofertas listadas na área exclusiva.",
          },
          {
            id: 6,
            question: "25. Não tenho cartão de credito para fazer a assinatura dos planos?",
            answer:
              "A plataforma no momento aceita apenas assinatura por cartão de credito, recomendamos ver a possibilidade de emissão de cartão de credito junto a sua agencia bancaria.",
          },
    ],
  },
  {
    id: 5,
    title: "Atendimento",
    questions: [
        {
            id: 1,
            question: "26. Tenho mais dúvidas, onde posso conseguir mais informações?",
            answer:
              "Se nossas perguntas frequentes não sanaram suas dúvidas, entre em contato pelos outros canais de comunicação, você encontrará a lista em nosso site.",
          },
    ],
  },
];
