import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {ApiService} from "../../../services/api";
import {ToastError} from "../../../services/toast-service";
import {Formik} from 'formik'
import * as Yup from 'yup'
import {FormInputSingle} from "../../../components/FormInput";
import {TokensEnum} from "../../../utils";
import ReactLoading from "react-loading";
import {ROUTES} from "../../../index";

import revealEyecon from "../../../assets/images/icons/visibility.svg"
import hideEyecon from "../../../assets/images/icons/visibility_off.svg"
import './styles.scss'

const LoginPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const [passwordFormat, setPasswordFormat] = useState("password")
    const [visible, setVisible] = useState(false)

    const login = async ({email, password}: any) => {
        setLoading(true)
        try {
            const result = await ApiService.post('customers/login', {
                customer: {
                    email,
                    password
                }
            })
            localStorage.setItem(TokensEnum.USER, JSON.stringify(result.data.data))
            localStorage.setItem(TokensEnum.KEY_TOKEN, result.headers.authorization)
            setLoading(false)
            window.location.reload()
        } catch (e: any) {
            setLoading(false)
            ToastError(e?.response?.data?.status?.message || e?.response?.data?.error || e.message)
        }
    }

    const verifyAccount = async () => {
        try {
            const resultItem = await ApiService.get(`api/current_customer`)
            const status = resultItem?.data?.data?.attributes?.status
            if (status === "pendente" || status === "cnpj_validado" || status === "invalido") {
                navigate(ROUTES.CREDIT_CARD)
            } else {
                window.location.replace(ROUTES.PRODUCTS)
            }
        } catch (e: any) {
            ToastError(e?.response?.data?.status?.message || e?.response?.data?.error  || e.message)
            navigate(ROUTES.CREDIT_CARD)
            console.log(e.message)
        }
    }

    useEffect(() => {
        const authorization = localStorage.getItem(TokensEnum.KEY_TOKEN)
        if (authorization && authorization !== '') {
            verifyAccount()
        }

    }, [])
    const SignInSchema = Yup.object().shape({
        email: Yup.string().email('Email invalido!').required('Email invalido.'),
        password: Yup.string().required('Senha invalida!.'),
    })
    return (
        <div className="container-login">
            <section className="login-section">
            <div className="container">
                <div className="row">
                    <div className="login-form col-md-12">
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            validateOnChange={false}
                            validationSchema={SignInSchema}
                            onSubmit={(values) => {
                                login(values)
                            }}
                        >
                            {({
                                  errors,
                                  handleSubmit,
                                  handleChange,
                                  isSubmitting,
                                submitForm,
                                  setFieldValue,
                                  isValid,
                                  dirty
                              }) => (
                        <form className="form-content" onSubmit={handleSubmit}>
                            <a href="/" className="d-flex justify-content-center">
                                <img src="/assets/images/logo-branca.svg" alt="logo"
                                     style={{maxHeight: '100px'}}/>
                            </a>
                            <p className="login-title mt-3 mb-2 d-flex justify-content-center">Bem vindo!</p>
                            <p className='mb-3 text-center'>
                                <span>Primeira vez aqui? </span>
                                <a href="javascript:void(0)" onClick={() => navigate(ROUTES.REGISTER)} className="pointer-event new-link anchor-style text-white">
                                    <span className="fw-bold">Cadastre-se.</span>
                                </a>
                            </p>
                            <div className="form-group">
                                <label className="mb-2 text-white">Email</label>
                                <input type="email" id="email" name="email"
                                       onChange={(event: any) => {
                                           setFieldValue('email', event.target.value)
                                           handleChange(event)
                                       }}
                                       className="form-control mb-5 custom-input"
                                       required autoComplete="email" autoFocus
                                       placeholder=""/>
                                <div>
                                    {errors.email !== undefined && (
                                        <div
                                            className='field-error'>{errors.email}</div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="mb-2 text-white">Senha</label>
                                <input type={visible? "text" : "password"} id="password" name="password"
                                       onChange={(event: any) => {
                                           setFieldValue('password', event.target.value)
                                           handleChange(event)
                                       }}
                                       className="form-control mb-1 custom-input" required
                                       autoComplete="current-password"
                                       placeholder=""/>
                                       <span className='btn__eye'><img onClick={()=> {setVisible(!visible)}} src={visible? hideEyecon : revealEyecon} /></span>
                                <div>
                                    {errors.password !== undefined && (
                                        <div
                                            className='field-error'>{errors.password}</div>
                                    )}
                                </div>
                            </div>
                            <div className="text-end mb-4">
                                <a className="new-link anchor-style text-white" href={ROUTES.FORGOT_PASSWORD}>Esqueceu a senha?</a>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-secondary fw-bolder mt-4 mb-4" onClick={submitForm}>
                                    {loading ?
                                        <div className='position-relative' style={{
                                            bottom: '23px',
                                            right: '-23px'
                                        }}>
                                            <div className='position-absolute'>
                                                <ReactLoading type={'balls'} color={'#ffffff'}
                                                              height={'80px'} width={'50px'}/>
                                            </div>
                                        </div>: 'Entrar'}
                                </button>
                            </div>
                        </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
        </div>
    );
}

export default LoginPage;
