import React from 'react';
import {
    Countdown,
    NewProduct,
} from "./components";
import HeroComponent from "./components/SliderItem";
import Prices from "./components/Prices";
import Teams from "./components/Teams";
import Faq from './components/Faq/Faq';
import './App.scss'
import {FloatingWhatsApp} from "./components/FloatingWhatsApp";

function App() {
    return <div className='App'>
        <HeroComponent/>
        <Countdown/>
        <NewProduct/>
        <Teams/>
        <Prices/>
        <Faq/>
        <FloatingWhatsApp/>
    </div>
}

export default App;
