import { Dialog } from "@mui/material/";
import "./OrderConfirmation.scss";
import {useCart} from "../../hooks/useCart";
import React from "react";

const OrderConfirmation = () => {
  const {modalOpen, setModalOpen, orders} = useCart()
  const handleClose = () => setModalOpen(false);



  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn-close" data-bs-dismiss="modal"
                    aria-label="Close" onClick={handleClose}></button>
          </div>
          <div className="modal-body pt-0">
            <h5 className="modal-title pb-4" id="orderConfirmationLabel">Confirmaçāo de Pedido</h5>
            <p>Obrigado por escolher a Xeque Mate!</p>
            <p><small>Estamos processando o seu pedido e em breve enviaremos um e-mail de confirmação com os detalhes.</small></p>
            <p><small>Os seu(s) número(s) de confirmaçāo sāo:</small></p>
            <p className="py-3">{ orders?.map((order:any) => ( <span className="p-1">#{order.id}</span> ) )}</p>
            <p><small>Nossos fornecedores entrarão em contato para garantir a excelência do seu pedido.</small></p>
            <p><small>- Equipe Xeque Mate</small></p>
          </div>
        </div>
    </Dialog>
  );
};

export default OrderConfirmation;
