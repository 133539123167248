const NewProduct = () => {
  return (
      <section className="offers-section">
        <div className="container-fluid">
          <div className="row mt-5 mb-5">
            <div className="col-md-12 d-flex justify-content-center">
              <p className="fw-bold" id="offer-title">Algumas de nossas ofertas Xeque Mate</p>
            </div>
          </div>
          <div className="owl-carousel">

            <div className="carousel-item">
              <div className="product-card">
                <img src="/assets/images/products/dobrad-click-back.png"
                     alt="new-product" className="product-image"/>
                  <div className="product-card-content"><h4 className="product-card-title"><a
                      href="javascript:void(0)">Dobradiça</a></h4>
                    {/* <p className="product-card-quantity mt-2">2 units per box</p> */}
                  </div>
                  <div className="product-card-button">
                    <a className="btn btn-primary card-btn" href="javascript:void(0)">Faça cadastro para ver</a>
                  </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="product-card">
                <img src="/assets/images/products/pistao-a-gas-back.png"
                     alt="new-product" className="product-image"/>
                  <div className="product-card-content"><h4 className="product-card-title"><a
                      href="javascript:void(0)">Pistão
                    a gas</a></h4>
                    {/* <p className="product-card-quantity mt-2">2 units per box</p> */}
                  </div>
                  <div className="product-card-button">
                    <a className="btn btn-primary card-btn" href="/login">Faça cadastro para ver</a>
                  </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="product-card">
                <img src="/assets/images/products/dobradi-a-Inox-back.png"
                     alt="new-product" className="product-image"/>
                  <div className="product-card-content"><h4 className="product-card-title"><a
                      href="javascript:void(0)">Dobradiça</a></h4>
                    {/* <p className="product-card-quantity mt-2">2 units per box</p> */}
                  </div>
                  <div className="product-card-button">
                    <a className="btn btn-primary card-btn" href="/login">Faça cadastro para ver</a>
                  </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="product-card">
                <img src="/assets/images/products/ferro-front.png"
                     alt="new-product" className="product-image"/>
                  <div className="product-card-content"><h4 className="product-card-title"><a
                      href="javascript:void(0)">Corrediça</a></h4>
                    {/* <p className="product-card-quantity mt-2">2 units per box</p> */}
                  </div>
                  <div className="product-card-button">
                    <a className="btn btn-primary card-btn" href="/login">Faça cadastro para ver</a>
                  </div>
              </div>
            </div>

          </div>
        </div>
      </section>
  );
};
export { NewProduct };
