import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../index";

const ForgotPasswordPage = () => {

    const navigate = useNavigate();
    const [current, setCurrent] = useState('email')
    const [email, setEmail] = useState<string>('')
    return (
        <div>
            <section className="container-fluid p-4">
                <div className="row position-relative animate__animated animate__fadeInLeft"
                     style={{minHeight: "calc(100vh - 48px)"}}>
                    <div className="bg-dark-blue-register"></div>
                    <img src="/images/surface-cavalo.svg"
                         className="horse-bg-forgot-passowrd" alt=""
                         onClick={() => navigate(ROUTES.LOGIN)}/>
                    <div className="col-lg-6 mt-5 ps-4">
                        <img src="/images/logo-vertical-branca.svg"
                             height="79px" style={{
                            cursor: 'pointer'
                        }} alt="" onClick={() => navigate(ROUTES.LOGIN)}
                        />
                        <div className="d-flex justify-content-center mt-5">
                            <div
                                className={current === 'email' ? "mx-3 button-step-register-active" : 'mx-3 button-step-register-disabled'}>
                                <span>Email</span>
                                <div className={current === 'email' ? "border-button-step-register-active" : 'border-button-step-register-disabled'}></div>
                            </div>
                            <div
                                className={current === 'code' ?  'mx-3 button-step-register-disabled' : "mx-3 button-step-register-active"}>
                                <span>Código</span>

                                <div className={current === 'code' ? "border-button-step-register-active" : 'border-button-step-register-disabled'}  id="borda-botao-personal-data"></div>
                            </div>
                            <div
                                className={current === 'password' ? 'mx-3 button-step-register-disabled' :"mx-3 button-step-register-active" }>
                                <span>Senha</span>

                                <div id="borda-botao-credentials" className={current === 'password' ? "border-button-step-register-active" : 'border-button-step-register-disabled'} ></div>
                            </div>
                        </div>
                        {current ==='email' ?<>
                            <h1 className="title-card-register mt-4">Recuperar senha</h1>
                            <p className="text-card-register">Informe seu e-mail de login para recuperar a senha!</p>
                            <form noValidate className="ng-pristine ng-invalid ng-touched">
                                <div className="animate__animated animate__fadeInRight ng-star-inserted">
                                    <div className="form-row">
                                        <input type="email" id="email" required
                                               onChange={(event) => {
                                                   setEmail(event.target.value || '')
                                               }}
                                               className="form-control form-textbox input-login-custom ng-pristine ng-invalid ng-touched"/>
                                        <label htmlFor="email" className="form-label mb-0">Email</label>
                                    </div>
                                    <button className="btn btn-confirm-register w-100" type='button' onClick={() => setCurrent('code')}>
                                        Enviar email
                                    </button>
                                    <div className="d-flex my-4 pt-2">
                                        <span className="text-diferent-login" style={{color: "white !important"}}>Não possui uma conta?&nbsp;</span>
                                        <a onClick={() => {
                                            navigate(ROUTES.REGISTER)
                                        }} className="link-click" style={{color: "white !important"}}>
                                            Crie uma
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </> : null}
                        {
                            current ==='password' ? <>
                                <h1 className="title-card-register mt-4">Recuperar senha</h1>
                                <p className="text-card-register">Informe sua nova senha!</p>
                                <form noValidate className="ng-pristine ng-invalid ng-touched">
                                    <div className="animate__animated animate__fadeInRight ng-star-inserted">
                                        <div className="form-row">
                                            <input type="password" id="password" required
                                                   className="form-control form-textbox input-login-custom ng-untouched ng-pristine ng-invalid"/>
                                            <label htmlFor="password" className="form-label mb-0">Senha</label>
                                        </div>
                                        <div className="form-row">
                                            <input type="password" id="password" required
                                                   className="form-control form-textbox input-login-custom ng-untouched ng-pristine ng-invalid"/>
                                            <label htmlFor="password" className="form-label mb-0">Confime Senha</label>
                                        </div>
                                        <button className="btn btn-confirm-register w-100" type='button'>
                                            Trocar senha
                                        </button>
                                        <div className="d-flex my-4 pt-2">
                                            <span className="text-diferent-login" style={{color: "white !important"}}>Não possui uma conta?&nbsp;</span>
                                            <a onClick={() => {
                                                navigate(ROUTES.REGISTER)
                                            }} className="link-click" style={{color: "white !important"}}>
                                                Crie uma
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </>: null
                        }
                        {
                            current ==='code' ? <>
                                <h1 className="title-card-register mt-4">Recuperar senha</h1>
                                <p className="text-card-register">Informe o codigo recebido por email!</p>
                                <form noValidate className="ng-pristine ng-invalid ng-touched">
                                    <div className="animate__animated animate__fadeInRight ng-star-inserted">
                                        <div className="form-row">
                                            <input type="email" id="email" required
                                                   onChange={(event) => {
                                                       setEmail(event.target.value || '')
                                                   }}
                                                   className="form-control form-textbox input-login-custom ng-pristine ng-invalid ng-touched"/>
                                            <label htmlFor="email" className="form-label mb-0">Codigo</label>
                                        </div>
                                        <button className="btn btn-confirm-register w-100" type='button' onClick={() => setCurrent('password')}>
                                            Enviar código
                                        </button>
                                        <div className="d-flex my-4 pt-2">
                                            <span className="text-diferent-login" style={{color: "white !important"}}>Não possui uma conta?&nbsp;</span>
                                            <a onClick={() => {
                                                navigate(ROUTES.REGISTER)
                                            }} className="link-click" style={{color: "white !important"}}>
                                                Crie uma
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </>: null
                        }
                    </div>
                    <div className="col-lg-6"></div>
                </div>
            </section>
        </div>
    );
}

export default ForgotPasswordPage;
