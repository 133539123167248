import React from "react";
import './Footer.scss'
const Footer = () => {
  const pathname = window.location.pathname

  return (

      <footer id='footer' className='Footer'>
        <div className="container footer-container">
          <div className="row">
            <div className="col-md-7 d-flex align-items-stretch justify-content-center">
              <div>
                <a className="logo" href="/">
                  <img className="img-fluid" src="/assets/images/logo-branca.svg" alt="logo"
                       style={{maxHeight: '100%', maxWidth: '100%'}}/>
                </a>
              </div>
              <div className="ms-5">
                <p className="fw-bold"> Xeque Mate- seu clube VIP de compras.</p>
                <h3 className="mt-3 mb-2">Sobre</h3>
                <ul className="custom-links">
                  <li><a href="/">Quem somos</a></li>
                  <li><a target="_blank"
                         href="/assets/docs/TERMOS_GERAIS_PLATAFORMA.pdf"
                  >Termos e Condições de
                    uso</a></li>
                  <li><a target="_blank" href="/assets/docs/termos-uso-plataforma.pdf">Politica de privacidade</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5 d-flex">
              <div className="mt-4">
                <h3 className="mt-3 mb-2">Entre em contato</h3>
                <ul className="custom-links">
                  <li><span>Endereço: </span> Rua Rosália Pedroni, n.333, Bairro <br/> Itaipava- Itajaí - SC</li>
                  <li><span>Email: </span><a id="email"
                                             href="mailto:contato@xequemate.com">contato@xequematecompras.com.br</a>
                  </li>
                  <li><span>Telefone: </span><a href="tel:+55 47 33499570">+55 47 33499570</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 d-flex justify-content-center">
              <ul className="social-links d-flex">
                <li>
                  <a href="#"
                     target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-whatsapp"></i>
                  </a></li>
                <li><a href="#" target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-instagram"></i>
                </a></li>
              </ul>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 d-flex justify-content-center">
              <p className="fs-5">© Xeque Mate - Todos os direitos reservados</p>
            </div>
          </div>
        </div>
      </footer>
  );
};

export { Footer };
