import {useEffect, useState} from "react";
import {categories} from "./Categories";
import {ClickEvent} from "../../types/types";

import "./Faq.scss"

const Faq = () => {
    const [category, setCategory] = useState("Planos");
    const [currentCategory, setCurrentCategory] = useState(categories[0])


    const changeCategory = () => {
        const selectedCategory = categories.find(
            (foundCategory) => foundCategory.title === category
        );
        setCurrentCategory(selectedCategory as any)
    }

    const clickHandler = () => {
        changeCategory()
    };

    useEffect(() => {
        clickHandler()
    }, [category])

    return (
        <section className="faq-section">
            <div className="container faq-container">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 d-flex justify-content-center">
                        <p className="supplier-title mt-3">Perguntas Frequentes</p>
                    </div>
                </div>
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 faq-btn-container d-flex flex-wrap justify-content-between">
                        {
                            [
                                'Planos',
                                'Compras',
                                'Acesso',
                                'Financeiro',
                                'Atendimento'
                            ].map((item, key) => {
                                return <a onClick={() => {
                                    setCategory(item)
                                }} className={category !== item ?"btn btn-faq mb-2 me-1": "btn btn-faq mb-2 me-1 faq-btn-selected"} href="javascript:void(0)" key={key}>{item}</a>
                            })
                        }
                    </div>
                </div>
                <div className="row mt-5 mb-5">
                    <div className="col-md-7">
                        {
                            currentCategory?.questions?.map((question, key) => {
                                return <div key={key}>
                                    <p className="faq-heading mt-4 mb-3">{question.question}</p>
                                    <p className="faq-answer d-flex flex-wrap ms-3">{question.answer}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;
