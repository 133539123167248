import React from 'react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {HashLink} from "react-router-hash-link";
import {ROUTES} from "../../index";

export default function HeroComponent() {
    return <section className="hero-main">
        <div className="container">
            <div className="row align-content-center">
                <div>
                    <h2 className="hero_title">a jogada certa <br/>para o seu <br/>negócio <br/></h2>
                    <HashLink className="btn btn-secondary-main cadastro-btn" to={ROUTES.REGISTER}>cadastro</HashLink>
                </div>
            </div>
        </div>
    </section>
}
