import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Register from "./pages/auth/register";
import LoginPage from "./pages/auth/login";
import ForgotPassword from "./pages/auth/forgotPassword";
import ProductsPage from './pages/ProductsPage/ProductsPage';
import ShoppingCartPage from './pages/ShoppingCartPage/ShoppingCartPage';
import ProductPage from './pages/ProductPage/ProductPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {CreditCard} from "./pages/auth/creditCard";
import { Header, Footer } from './components';
import ProfilePage from './pages/profile/ProfilePage';
import WhatsApp from './components/WhatsApp/WhatsApp';
import OrderConfirmation from './components/OrderConfirmation/OrderConfirmation';
import MyRequest from "./pages/MyRequest/MyRequest";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
export enum ROUTES {
    HOME = '/',
    REGISTER = '/register',
    LOGIN = '/login',
    FORGOT_PASSWORD = 'https://app.xequematecompras.com/customers/password/new',
    PROFILE = '/profile',
    CREDIT_CARD = '/profile/credit-card',
    PRODUCTS = '/products',
    MY_PLAN = '/profile',
    MY_REQUEST = '/profile',
    MY_REQUEST_DETAILS = '/my-request/:id',
    PRODUCT_ID = '/products/:id',
    MY_CART = '/my-cart',
    LOGIN_FORNECEDOR = 'https://app.xequematecompras.com/users/sign_in'
}

root.render(
  <React.StrictMode>
      <Router>
          <Header />
          <OrderConfirmation/>
          <Routes>
              <Route path={ROUTES.HOME} element={<App />} />
              <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
              <Route path={ROUTES.CREDIT_CARD} element={<CreditCard />} />
              <Route path={ROUTES.REGISTER} element={<Register />} />
              <Route path={ROUTES.LOGIN} element={<LoginPage />} />
              <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
              <Route path={ROUTES.PRODUCTS} element={<ProductsPage />} />
              <Route path={ROUTES.PRODUCT_ID} element={<ProductPage />} />
              <Route path={ROUTES.MY_CART} element={<ShoppingCartPage />} />
              <Route path={ROUTES.MY_REQUEST_DETAILS} element={<MyRequest />} />
          </Routes>
          <Footer />
          <ToastContainer />
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
