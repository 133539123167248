import "./Teams.scss"

const Teams = () => {
    return(
        <section className="supplier-section supplier__section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-lg-single"></div>
                    <div className="col-lg-6 col-lg-single">
                        <div className="supplier-text">
                            <h2 className="supplier-title mb-4">Nossos Fornecedores</h2>
                            <p className="supplier-p">A Xeque Mate escolhe seus fornecedores de forma cuidadosa e
                                estratégica, para
                                assim
                                proporcionar aos clientes o melhor custo benefício na aquisição de produtos, trabalhamos
                                para
                                que
                                nossos fornecedores ofertem o melhor em qualidade com preços competitivos, fazendo você
                                cliente
                                ter
                                benefícios jamais oferecidos no seguimento</p>
                            <div className="text-end">
                                {/* <a href="javascript:void(0)" className="btn btn-secondary-main compre-btn text-right">Assine
                                    Agora
                                </a> */}
                            </div>
                            <div className="supplier-fornecedores text-end">
                                {/* <img className="supplier-logos img-fluid mb-4"
                                     src="/assets/images/logo-cluster.png" alt=""/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
export default Teams