import React from "react";
import './styles.scss'
export const FloatingWhatsApp = () => {
    return <div className="FloatingWhatsApp">
        <a href="https://api.whatsapp.com/send?phone=554733499570&text=Olá, gostaria de saber mais sobre a Xeque-Mate"
           className="float" target="_blank">
            {/*<img src="/assets/images/whatsapp.svg" alt=""/>*/}
            <i className="fa-brands fa-whatsapp" style={{color: '#17d709'}}></i>
            {/*<i className="fa fa-whatsapp my-float"></i>*/}
        </a>
        {/*<a href="https://api.whatsapp.com/send?phone=554733499570&text=Olá, gostaria de saber mais sobre a Xeque-Mate"*/}
        {/*   className="whatsapp-float" target="_blank">ewqeqweqw*/}
        {/*    <i className="fa fa-whatsapp whatsapp-my-float"></i>*/}
        {/*</a>*/}
    </div>
}