import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Formik} from 'formik'
import * as Yup from "yup";
import {ApiService} from "../../services/api";
import {ToastError} from "../../services/toast-service";
import "./RegisterPage.scss"
import ReactLoading from "react-loading";
import {ROUTES} from "../../index";
import AccountConfirmation from "../AccountConfirmation/AccountConfirmation";
import {TokensEnum} from "../../utils";

interface IPayload {
    picked: string
    email: string
    password: string
    phone: string
    cnpj: string
    password_confirmation: string
}

const RegisterPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [term, setTerm] = useState(false)
    const [isClient, setIsClient] = useState<null | boolean>(() => {
        if (window.location.search.indexOf('?type=fornecedor') >= 0) {
            return false
        }
        return null
    })
    const [modalAccountConfirmation, setModalAccountConfirmation] = useState<boolean>(false)

    const SignInSchema = Yup.object().shape({
        picked: Yup.string().required('Por favor, escolhe uma opção'),
        term: Yup.string().required('Por favor, aceite o termo'),
        email: Yup.string().email('E-mail invalido.').required('Campo obrigatorio.'),
        cnpj: Yup.string().required('Campo obrigatorio.').matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/, 'CNPJ invalido!'),
        phone: Yup.string().required('Campo obrigatorio.'),
        password: Yup.string()
            .required('Campo obrigatorio.'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password')], 'As duas senhas tem que ser iguais')
            .required('Campo obrigatorio.'),
    })

    const telefoneMask = (value: any) => {
        const numeroLimpo = value.replace(/\D+/g, '');
        let numeroFormatado = '';

        if (numeroLimpo.length === 11) {
            const ddd = numeroLimpo.slice(0, 2);
            const parte1 = numeroLimpo.slice(2, 3);
            const parte2 = numeroLimpo.slice(3, 7);
            const parte3 = numeroLimpo.slice(7, 11);
            numeroFormatado = `(${ddd}) ${parte1} ${parte2}-${parte3}`;
        } else if (numeroLimpo.length === 10) {
            const ddd = numeroLimpo.slice(0, 2);
            const parte1 = numeroLimpo.slice(2, 6);
            const parte2 = numeroLimpo.slice(6, 10);
            numeroFormatado = `(${ddd}) ${parte1}-${parte2}`;
        } else {
            numeroFormatado = value;
        }
        return numeroFormatado.slice(0, 16);
    };

    const cnpjMask = (value: any) => {
        return value
            .replace(/\D+/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
    }

    const register = async (values: IPayload) => {
        let payload = {}
        let url = 'customers/signup'

        if (!isClient) {
            url = '/api/suppliers'
            payload = {
                supplier: {
                    email: values.email,
                    cnpj: values.cnpj,
                    phone: values.phone,
                    user_attributes: {
                        password: values.password,
                        password_confirmation: values.password_confirmation
                    }
                }
            }
        } else {
            payload = {
                "customer": {
                    email: values.email,
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                    cnpj: values.cnpj,
                    phone: values.phone,
                }
            }
        }

        setLoading(true)

        try {
            const result = await ApiService.post(url, payload)
            localStorage.setItem(TokensEnum.KEY_TOKEN, result.headers.authorization)

            setLoading(false)

            if (isClient) {
                window.location.replace(ROUTES.CREDIT_CARD)
            } else {
                setModalAccountConfirmation(true);
            }
        } catch (e: any) {
            setLoading(false)
            ToastError(e?.response?.data?.status?.message || e.message)
        }
    }


    return (
        <div className="RegisterPage">
            <section className="register-section">
                <div className="container">
                    <div className="row">
                        {modalAccountConfirmation ? <AccountConfirmation/> : null}
                        <div className="register-form col-md-12">
                            <Formik
                                initialValues={{
                                    picked: '',
                                    email: '',
                                    term: '',
                                    password: '',
                                    phone: '',
                                    cnpj: '',
                                    password_confirmation: '',
                                }}
                                validateOnChange={false}
                                validationSchema={SignInSchema}
                                onSubmit={(values) => {
                                    register(values)
                                }}
                            >
                                {({
                                      errors,
                                      values,
                                      setFieldValue,
                                      handleSubmit,
                                      handleChange,
                                      isSubmitting,
                                      isValid,
                                      dirty
                                  }) => (
                                    <form className="form-content" onSubmit={handleSubmit}>
                                        <a href="/" className="d-flex justify-content-center">
                                            <img src="/assets/images/logo-branca.svg" alt="logo"
                                                 style={{maxHeight: '100px'}}/>
                                        </a>
                                        <p className="register-title mt-3 mb-2 d-flex justify-content-center">Criar
                                            conta</p>
                                        <p className="register-subtitle mb-3 d-flex justify-content-center fw-bold fs-5">Suas
                                            vendas
                                            simplificadas!</p>
                                        <p className="register-para mb-4 d-flex justify-content-center align-items-center">
                                            Crie uma conta e conecte-se a uma rede de distribuidores, potencialize suas
                                            vendas,
                                            facilite
                                            suas compras e aumente o alcance do seu negócio.
                                        </p>
                                        <div className="radio-group mb-4">
                                            <label className="radio-label">
                                                <input type="radio" name="option" value="option1" checked={(isClient && true) as boolean} onClick={(event: any) => {
                                                    setIsClient(true)
                                                    setFieldValue('picked', 'option1')
                                                    handleChange(event)
                                                }}/>
                                                <span className="radio-text fw-bold fs-5"> <span
                                                    className="radio-text-color">Sou cliente </span> (quero comprar produtos)</span>
                                            </label>
                                            <label className="radio-label">
                                                <input type="radio" name="option" value="option2" checked={!isClient  && isClient !== null} onClick={(event) => {
                                                    // cliente
                                                    setIsClient(false)
                                                    setFieldValue('picked', 'option1')
                                                    handleChange(event)
                                                }}/>
                                                <span className="radio-text fw-bold fs-5"> <span
                                                    className="radio-text-color">Sou fornecedor </span>(quero vender produtos)</span>
                                            </label>
                                            <div>
                                                {errors.picked !== undefined && (
                                                    <div className='field-error'>{errors.picked}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <label className="mb-3 text-white">CNPJ</label>
                                                <input type="text" id="cnpj" name="cnpj"
                                                       className="form-control mb-5 custom-input"
                                                       value={cnpjMask(values.cnpj)}
                                                       onChange={(event: any) => {
                                                           setFieldValue('cnpj', event.target.value)
                                                           handleChange(event)
                                                       }}
                                                       required autoComplete="cnpj" autoFocus
                                                       placeholder=""/>
                                                <div>
                                                    {errors.cnpj !== undefined && (
                                                        <div className='field-error'>{errors.cnpj}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="mb-3 text-white">Email</label>
                                                <input type="email" id="email" name="email"
                                                       onChange={(event: any) => {
                                                           setFieldValue('email', event.target.value)
                                                           handleChange(event)
                                                       }}
                                                       className="form-control mb-5 custom-input"
                                                       required autoComplete="email" autoFocus
                                                       placeholder=""/>
                                                <div>
                                                    {errors.email !== undefined && (
                                                        <div>{errors.email}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="mb-3 text-white">Telefone</label>
                                                <input type="text" id="telephone" name="phone"
                                                       onChange={(event: any) => {
                                                           setFieldValue('phone', telefoneMask(event.target.value))
                                                           handleChange(event)
                                                       }}
                                                       value={telefoneMask(values.phone)}
                                                       className="form-control mb-5 custom-input"
                                                       required autoComplete="phone" autoFocus
                                                       placeholder=""/>
                                                <div>
                                                    {errors.phone !== undefined && (
                                                        <div>{errors.phone}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="mb-3 text-white">Senha</label>
                                                <input type="password" id="password"
                                                       onChange={(event: any) => {
                                                           setFieldValue('password', event.target.value)
                                                           handleChange(event)
                                                       }}
                                                       name="password"
                                                       className="form-control mb-5 custom-input"
                                                       required autoComplete="password" autoFocus
                                                       placeholder=""/>
                                                <div>
                                                    {errors.password !== undefined && (
                                                        <div className='field-error'>{errors.password}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="mb-3 text-white">Confirmar senha</label>
                                                <input type="password"
                                                       onChange={(event: any) => {
                                                           setFieldValue('password_confirmation', event.target.value)
                                                           handleChange(event)
                                                       }}
                                                       id="confirm-password" name="password"
                                                       className="form-control mb-1 custom-input" required
                                                       autoComplete="current-password"
                                                       placeholder=""/>
                                                {errors.password_confirmation !== undefined && (
                                                    <div
                                                        className='field-error'>{errors.password_confirmation}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="radio-group mb-4">
                                            <label className="radio-label">
                                                <input type="radio" name="term" checked={term as boolean} onClick={(event: any) => {
                                                    setTerm(!term)
                                                    setFieldValue('term', term ? null : true)
                                                    handleChange(event)
                                                }}/>
                                                <span className="radio-text fw-bold fs-5"> <span
                                                    className="radio-text-color">Li e aceito os </span>
                                                <a className={''} target="_blank"
                                                   href="/assets/docs/TERMOS_GERAIS_PLATAFORMA.pdf">termos da plataforma</a>
                                                </span>
                                            </label>
                                            <div>
                                                {errors.term !== undefined && (
                                                    <div className='field-error'>{errors.term}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-4 d-flex">
                                            <div className="col-md-8">
                                                <a className="anchor-style text-white" href="/login">Já possui uma
                                                    conta? <span
                                                        className="fw-bold">Clique aqui.</span></a>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="text-end criar-btn">
                                                    <button disabled={loading} type='submit' className="btn btn-secondary fw-bolder">
                                                        {loading ?
                                                            <div className='position-relative' style={{
                                                                bottom: '23px',
                                                                right: '-23px'
                                                            }}>
                                                                <div className='position-absolute'>
                                                                    <ReactLoading type={'balls'} color={'#ffffff'}
                                                                                  height={'80px'} width={'50px'}/>
                                                                </div>
                                                            </div> : 'CRIAR'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default RegisterPage;
