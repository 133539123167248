import {useParams} from "react-router";
import React, {MouseEventHandler, useEffect, useState} from "react";
import {createCartItem, getSale, getShoppingCart} from "../../utils/axiosCalls";
import {TokensEnum, calculateExpirationDate, moneyFormat, parseImage} from "../../utils";
import backArrow from "../../assets/arrow_back.png"
import {ROUTES} from "../../index";
import useAuth from "../../hooks/useAuth";
import {useCart} from "../../hooks/useCart";
import {getSales} from "../../utils/axiosCalls";
import './../ProductsPage/ProductsPage.scss'
import {Link, useNavigate} from 'react-router-dom';
import {ToastError} from "../../services/toast-service";
import "./ProductPage.scss";

const ProductPage: React.FC = () => {
    useAuth({})
    const {id} = useParams();
    const [countProduct, setCountProduct]: [countProduct: any, setCountProduct: Function] = useState()
    const [singleProduct, setSingleProduct]: [singleProduct: any, setSingleProduct: Function] = useState<any>();
    const [cart, setCart]: [cart: any, setCart: Function] = useState()
    const [cartId, setCartId] = useState("")
    const {setCartItems, cartItems} = useCart()
    const [querySupplier, setQuerySupplier] = useState('')
    const [value, setValue] = useState("")
    const [salesDataArray, setSalesDataArray]: [salesDataArray: any, setSalesDataArray: Function] = useState(null)
    const [openMenu, setOpenMenu] = useState(false);
    const [displayedImg, setDisplayedImg] = useState("");
    const [currentImage, setCurrentImage] = useState(null)

    const searchListData: any[] = [
        {productName: "dobradiça"},
        {productName: "parafuso"},
        {productName: "empilhadeira"},
        {productName: "empilhadeira eléctrica"},
        {fornecedor: "Teste Fornecedor"},
        {fornecedor: "Dublin Teste Fornecedor"},
        {category: "adesivo"},
        {category: "ferragem"},
        {category: "ferramentas"}
    ]
    const navigate = useNavigate();

    const authorization = localStorage.getItem(TokensEnum.KEY_TOKEN)

    useEffect(() => {
        getSale(authorization, id!, setSingleProduct)
    }, [])

    useEffect(() => {
        getShoppingCart(authorization, setCart, setCartItems, setCartId)
    }, [])

    const clickHandler: Function = async (saleId: number | string) => {
        try {
            await createCartItem(cartId, saleId, countProduct)
            await getShoppingCart(authorization, setCart, setCartItems, setCartId)
            navigate(ROUTES.MY_CART)
        } catch (e: any) {
            ToastError(e?.response?.data?.message || e.message)
        }
    }

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        setQuerySupplier(e.currentTarget.value)
    }

    const onSearch = (searchTerm: string) => {
        //axios call to get sales with search term
        setValue(searchTerm)
        console.log("Search:", searchTerm)
    }

    const doSearch = (event: any) => {
        event.preventDefault()
        getSales(setSalesDataArray, querySupplier)
    }

    const timer = (expirationDate: string) => {
        const timestamp = Date.parse(expirationDate);
        const today = Date.now();
        const timeLeft = timestamp - today;
        const timeDays = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const timeLeftHours = timeLeft - timeDays * (1000 * 60 * 60 * 24);
        const timeHours = (Math.floor(timeLeftHours / (1000 * 60 * 60)));
        const timeLeftMinutes = timeLeftHours - timeHours * (1000 * 60 * 60);
        const timeMinutes = Math.floor(timeLeftMinutes / (1000 * 60));
        const timeDisplay = `${timeDays}D : ${timeHours}H : ${timeMinutes}min`;

        return timeDisplay;
    }

    const handleSelectedImg = (e: any) => {
        e.preventDefault();
        const selectedImg = document.querySelectorAll(".product-card__small-img");
        selectedImg.forEach(img => {
            img.classList.remove("active");
            img.closest("button")?.classList.remove("active");
        })
        setDisplayedImg((e.target.parentElement.value) || (e.target.value));
        e.target.closest("button")?.classList.add("active");
    }


    const handleOpenMenu = (e: any) => {
        e.preventDefault();
        setOpenMenu(true);
    }

    const handleCloseMenu = (e: any) => {
        setOpenMenu(false);
    }
    return (
        <div className="ProductPage">
            <div className="product-page-wrapper">
                <div className="breadcrumb-main-area">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="breadcrumb-left">
                                        <nav>
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="javascript:void(0)"
                                                                                   onClick={() => navigate(ROUTES.PRODUCTS)}>Home</a>
                                                </li>
                                                <li className="breadcrumb-item" aria-current="page">
                                                    <a href="javascript:void(0)"
                                                       onClick={() => navigate(ROUTES.PRODUCTS)}>Produtos</a></li>
                                                <li className="breadcrumb-item active" aria-current="page"> {singleProduct?.product?.name}</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="filters-right">
                                        <div className="grid-button">
                                            <div className="list-view">
                                                <img src="/assets/images/list-view-icon.svg" alt="Icon"/>
                                            </div>
                                            <div className="grid-view d-none">
                                                <img src="/assets/images/grid-view-icon.svg" alt="Icon"/>
                                            </div>
                                        </div>
                                        <div className="filter-main">
                                            <i className="fa-solid fa-filter"></i>
                                            Filter
                                        </div>
                                        <div className="search-main">
                                            <input className="form-control" type="text" placeholder=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="promo-bannar-area">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="promo-banner-main">
                                        <div className="promo-banner-image">
                                            <img src="/assets/images/promo-banner.png" alt="Promo Banner"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product-main-area">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                   <Link to="/products"><button type="button" className="btn btn-secondary addtocart-button">VOLTAR</button></Link>
                                    {/* <div className="category-left-menu">
                                        <ul>
                                            <li><a href="#">Ferramentas <i
                                                className="fa-solid fa-chevron-right"></i></a>
                                            </li>
                                            <li><a href="#">Adesivos <i className="fa-solid fa-chevron-right"></i></a>
                                            </li>
                                            <li><a href="#">Iluminacao <i className="fa-solid fa-chevron-right"></i></a>
                                            </li>
                                            <li><a href="#">Maquinas <i className="fa-solid fa-chevron-right"></i></a>
                                            </li>
                                            <li><a href="#">Ferragem <i className="fa-solid fa-chevron-right"></i></a>
                                            </li>
                                            <li><a href="#">Ferramentas <i
                                                className="fa-solid fa-chevron-right"></i></a>
                                            </li>
                                            <li><a href="#">Adesivos <i className="fa-solid fa-chevron-right"></i></a>
                                            </li>
                                            <li><a href="#">Iluminacao <i className="fa-solid fa-chevron-right"></i></a>
                                            </li>
                                            <li><a href="#">Maquinas <i className="fa-solid fa-chevron-right"></i></a>
                                            </li>
                                            <li><a href="#">Ferragem <i className="fa-solid fa-chevron-right"></i></a>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className="col-md-9">

                                    <div className="product-detail-area">
                                        <div className="product-view">
                                            <div className="product-view-image">
                                                <img src={parseImage(currentImage || singleProduct?.product?.images[0]?.image)}
                                                     alt="Product"/>
                                                <div className="product-time-date position-absolute">
                                                    <h3>{calculateExpirationDate(singleProduct?.['expiration-date'])}</h3>
                                                </div>
                                            </div>
                                            <div className="product-image-list">
                                                <ul>
                                                    {singleProduct?.product?.images.map((image: any, key: number) => {
                                                        return <li onClick={() => setCurrentImage(image.image)} onMouseEnter={() => setCurrentImage(image.image)}>
                                                            <a href="javascript:void(0)" key={key}><img
                                                                src={image.thumb}
                                                                alt="Product"/></a>
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-information">
                                            <div className="product-title d-flex gap-5">
                                                <h1>{singleProduct?.product?.name}</h1>
                                                <p className="price-section mt-1">
                                                    <small>Lote disponível: {singleProduct?.quantity}</small>
                                                </p>
                                            </div>
                                            <div className="product-description">
                                                <p>
                                                    {singleProduct?.product?.description}
                                                </p>
                                            </div>
                                            <div className="product-description mt-2">
                                                <h4 className="quantity__title">Informações adicionais</h4>
                                                <p>
                                                    <small>{singleProduct?.product?.["additional-information"]}</small>
                                                </p>
                                            </div>
                                            <div className='product-description d-flex-row align-items-center '>
                                                <dl className="">
                                                    <dt>Categoria</dt>
                                                    <dd>{singleProduct?.product?.category?.name}</dd>
                                                    <dt>Fornecedor</dt>
                                                    <dd>{singleProduct?.product?.supplier?.name || 'Xeque'}</dd>
                                                </dl>
                                            </div>
                                            <div className="d-flex justify-content-end gap-3">
                                                <div className="price">
                                                    {`R$${parseFloat(singleProduct?.price).toFixed(2)}`}
                                                </div>
                                                <div className="price ">
                                                    <small>/ {singleProduct?.product?.unit}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-form-main">
                                            <div className="quantity-box price-section">
                                                <p className="quantity__quantity">Quantidade</p>
                                                {/* <button className="decrement"
                                                        disabled={countProduct <=0 ||singleProduct?.quantity <=0 }
                                                        onClick={() => {
                                                    if (countProduct > 1) {
                                                        setCountProduct(countProduct - 1)
                                                    }
                                                }}>-
                                                </button> */}
                                                <input defaultValue={singleProduct?.quantity === 0 ? singleProduct?.quantity : countProduct} type="number" className="quantity"
                                                       onChange={(event) => {
                                                           const num = parseInt(event.target.value)
                                                           setCountProduct(num)
                                                    // if (num <= singleProduct?.quantity) {
                                                    //     setCountProduct(num)
                                                    // }
                                                    // || countProduct > singleProduct?.quantity
                                                }}

                                                />
                                                {/* <button className="increment"
                                                        disabled={countProduct >= singleProduct?.quantity}
                                                        onClick={() => setCountProduct(countProduct + 1)}>+
                                                </button> */}
                                            </div>
                                            <button type="button" className="btn btn-secondary addtocart-button"
                                                    disabled={singleProduct?.quantity === 0 || countProduct === 0 || countProduct > singleProduct?.quantity}

                                                    onClick={(e) => {
                                                        clickHandler(singleProduct?.id)
                                                    }}>ADICIONAR AO CARRINHO
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ProductPage;
